import Constructed from '../../tools/constructed/Constructed';
import CvUser from '../../types/business/cv/fields/user/CvUser';
import ConstructedCvUser from '../../types/business/cv/fields/user/constructed/ConstructedCvUser';
import CvActivity from '../../types/business/cv/fields/activity/CvActivity';
import ConstructedCvActivity from '../../types/business/cv/fields/activity/constructed/ConstructedCvActivity';
import CvUserConstructFlow, {
  CV_USER_CONSTRUCT_FLOW,
} from '../../types/business/cv/fields/user/constructed/CvUserConstructFlow';
import CvActivitiesConstructFlow, {
  CV_ACTIVITIES_CONSTRUCT_FLOW,
} from '../../types/business/cv/fields/activity/constructed/CvActivitiesConstructFlow';
import FilterEntity from '../../types/business/filter/stash/entity/FilterEntity';
import {
  SET_ACTUAL_CV_ACTIVITIES,
  SET_ACTUAL_CV_USER,
  SET_FILTER_ENTITY,
  SET_INITIAL_CV_ACTIVITIES,
  SET_INITIAL_CV_USER,
} from '../actions/cv/cvActionTypes';
import { SetInitialCvUser } from '../actions/cv/setInitialCvUser';
import { SetActualCvActivities } from '../actions/cv/setActualCvActivities';
import { SetInitialCvActivities } from '../actions/cv/setInitialCvActivities';
import { SetActualCvUser } from '../actions/cv/setActualCvUser';
import { SetFilterEntity } from '../actions/cv/setFilterEntity';

interface CvStore {
  initialCvUser: Constructed<
    CvUserConstructFlow,
    ConstructedCvUser | undefined
  >;
  initialCvActivities: Constructed<
    CvActivitiesConstructFlow,
    ConstructedCvActivity[] | undefined
  >;
  actualCvUser?: CvUser;
  actualCvActivities?: CvActivity[];
  // TODO: Transform entity to FilterStash to enable caching
  filterEntity: FilterEntity;
}

const initialState: CvStore = {
  initialCvUser: { flow: CV_USER_CONSTRUCT_FLOW, value: undefined },
  initialCvActivities: {
    flow: CV_ACTIVITIES_CONSTRUCT_FLOW,
    value: undefined,
  },
  actualCvUser: undefined,
  actualCvActivities: undefined,
  filterEntity: {
    filters: [],
  },
};

type CvReducerTypes =
  | SetInitialCvUser
  | SetInitialCvActivities
  | SetActualCvUser
  | SetActualCvActivities
  | SetFilterEntity;

export const cvReducer = (
  state = initialState,
  action: CvReducerTypes,
): CvStore => {
  switch (action.type) {
    case SET_INITIAL_CV_USER: {
      return {
        ...state,
        initialCvUser: action.payload,
      };
    }
    case SET_INITIAL_CV_ACTIVITIES: {
      return {
        ...state,
        initialCvActivities: action.payload,
      };
    }
    case SET_ACTUAL_CV_USER: {
      return {
        ...state,
        actualCvUser: action.payload,
      };
    }
    case SET_ACTUAL_CV_ACTIVITIES: {
      return {
        ...state,
        actualCvActivities: action.payload,
      };
    }
    case SET_FILTER_ENTITY: {
      return {
        ...state,
        filterEntity: action.payload,
      };
    }
    default:
      return state;
  }
};
