import Filter from '../../../../types/business/filter/common/Filter';
import stringifyCompare from '../../../utils/stringifyCompare';

const sortFilters = (filters: Filter[]): Filter[] =>
  filters.sort((a, b) => {
    const typeCompareResult = a.type.localeCompare(b.type);
    if (typeCompareResult === 0) {
      return stringifyCompare(a.data, b.data);
    }
    return typeCompareResult;
  });

export default sortFilters;
