import React from 'react';

import { Typography, TypographyProps } from '@mui/material';

type TypographyAtomProps = TypographyProps & {
  cursor?: 'pointer' | 'default';
};

const TypographyAtom: React.FC<TypographyAtomProps> = ({
  className,
  component = 'span',
  variant,
  display,
  width,
  maxWidth,
  align,
  fontSize,
  color,
  cursor = 'default',
  sx,
  onClick,
  children,
}) => (
  <Typography
    className={className}
    component={component}
    variant={variant}
    display={display}
    width={width}
    maxWidth={maxWidth}
    align={align}
    fontSize={fontSize}
    color={color}
    sx={{ ...sx, cursor }}
    onClick={onClick}>
    {children}
  </Typography>
);

export default TypographyAtom;
