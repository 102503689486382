import { Dispatch } from 'redux';

type LoadResourceRequestAction<M> = {
  type: M;
};

type LoadResourceResponseAction<N, V> = {
  type: N;
  payload: V;
};

type LoadResourceErrorAction<P> = {
  type: P;
  payload: string;
};

export type LoadResource<M, N, P, V> =
  | LoadResourceRequestAction<M>
  | LoadResourceResponseAction<N, V>
  | LoadResourceErrorAction<P>;

const loadResource =
  <M, N, P, V>(request: M, response: N, error: P, fetch: () => Promise<V>) =>
  async (dispatch: Dispatch<LoadResource<M, N, P, V>>) => {
    dispatch({ type: request });
    try {
      const data: V = await fetch();
      dispatch({ type: response, payload: data });
    } catch (e) {
      dispatch({ type: error, payload: (e as Error).message });
    }
  };

export default loadResource;
