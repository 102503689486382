export const GITLAB_SOCIAL_LINK_TYPE = 'gitlab';
export const GITHUB_SOCIAL_LINK_TYPE = 'github';
export const LINKEDIN_SOCIAL_LINK_TYPE = 'linkedin';
export const COMMON_SOCIAL_LINK_TYPE = 'common';

type SocialLinkType =
  | typeof GITLAB_SOCIAL_LINK_TYPE
  | typeof GITHUB_SOCIAL_LINK_TYPE
  | typeof LINKEDIN_SOCIAL_LINK_TYPE
  | typeof COMMON_SOCIAL_LINK_TYPE;
export default SocialLinkType;
