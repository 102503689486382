import GoogleMapsSearch from '../GoogleMapsSearch';
import CoordinatesGoogleMapsSearch from '../CoordinatesGoogleMapsSearch';
import PlaceGoogleMapsSearch from '../PlaceGoogleMapsSearch';

import GoogleMapsSearchQuery from '../query/GoogleMapsSearchQuery';
import PromptGoogleMapsSearchQuery from '../query/PromptGoogleMapsSearchQuery';
import PlaceGoogleMapsSearchQuery from '../query/PlaceGoogleMapsSearchQuery';
import CoordinatesGoogleMapsSearchQuery from '../query/CoordinatesGoogleMapsSearchQuery';

import isPlaceGoogleMapsSearch from '../guards/isPlaceGoogleMapsSearch';
import isCoordinatesGoogleMapsSearch from '../guards/isCoordinatesGoogleMapsSearch';
import isPromptGoogleMapsSearch from '../guards/isPromptGoogleMapsSearch';
import apiGoogleMapsSearchQueryValue from '../query/api/apiGoogleMapsSearchQueryValue';

const getCoordinatesQuery = (
  coordinates: CoordinatesGoogleMapsSearch | PlaceGoogleMapsSearch,
): string => `${coordinates.latitude},${coordinates.longitude}`;

const googleMapsSearchToQuery = (
  search: GoogleMapsSearch,
): GoogleMapsSearchQuery | undefined => {
  if (isPlaceGoogleMapsSearch(search)) {
    return {
      ...apiGoogleMapsSearchQueryValue,
      query: getCoordinatesQuery(search),
      query_place_id: search.placeId,
    } as PlaceGoogleMapsSearchQuery;
  }
  if (isCoordinatesGoogleMapsSearch(search)) {
    return {
      ...apiGoogleMapsSearchQueryValue,
      query: getCoordinatesQuery(search),
    } as CoordinatesGoogleMapsSearchQuery;
  }
  if (isPromptGoogleMapsSearch(search)) {
    return {
      ...apiGoogleMapsSearchQueryValue,
      query: search.prompt,
    } as PromptGoogleMapsSearchQuery;
  }

  return undefined;
};

export default googleMapsSearchToQuery;
