import ConstructedCvActivity from '../ConstructedCvActivity';
import SubactivitiesResource from '../../../../../../../tools/resources/types/business/subactivities/SubactivitiesResource';
import CvActivitySubactivityId from '../fields/CvActivitySubactivityId';
import ConstructedCvActivitySubactivity from '../../fields/subactivities/constructed/ConstructedCvActivitySubactivity';
import { createSubactivityFromResource } from '../../fields/subactivities/CvActivitySubactivity';

const updateConstructedActivityWithSubactivitiesResource = (
  constructedActivity: ConstructedCvActivity,
  subactivitiesResource: SubactivitiesResource,
): ConstructedCvActivity => ({
  ...constructedActivity,
  subactivities: ((): ConstructedCvActivitySubactivity[] | undefined => {
    if (constructedActivity.subactivities?.length === 0) {
      return [];
    }

    const subactivities: ConstructedCvActivitySubactivity[] = [];
    constructedActivity.subactivities?.forEach((subactivity) => {
      const foundSubactivityResource = subactivitiesResource.find(
        (subactivityResource) =>
          (subactivity as CvActivitySubactivityId) === subactivityResource.id,
      );
      if (foundSubactivityResource) {
        subactivities.push(
          createSubactivityFromResource(foundSubactivityResource),
        );
      }
    });

    if (subactivities.length > 0) {
      return subactivities;
    }
    return undefined;
  })(),
});

export default updateConstructedActivityWithSubactivitiesResource;
