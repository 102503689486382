import { combineReducers } from 'redux';

import { appReducer } from './appReducer';
import { resourcesReducer } from './resourcesReducer';
import { cvReducer } from './cvReducer';

export const rootReducer = combineReducers({
  appReducer,
  resourcesReducer,
  cvReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
