export const ICV_LOGO_ALT_MAPPING = 'media.svg.icvLogo.alt';
export const GITLAB_LOGO_ALT_MAPPING = 'media.svg.gitlabLogo.alt';
export const GITHUB_LOGO_ALT_MAPPING = 'media.svg.githubLogo.alt';
export const LINKEDIN_LOGO_ALT_MAPPING = 'media.svg.linkedinLogo.alt';
export const TELEGRAM_LOGO_ALT_MAPPING = 'media.svg.telegramLogo.alt';
export const AVATAR_PLACEHOLDER_ALT_MAPPING = 'media.svg.avatarPlaceholder.alt';
export const EMPTY_BOX_ALT_MAPPING = 'media.svg.emptyBox.alt';

export type AssetsLocaleNamespaceMappings =
  | typeof ICV_LOGO_ALT_MAPPING
  | typeof GITLAB_LOGO_ALT_MAPPING
  | typeof GITHUB_LOGO_ALT_MAPPING
  | typeof LINKEDIN_LOGO_ALT_MAPPING
  | typeof TELEGRAM_LOGO_ALT_MAPPING
  | typeof AVATAR_PLACEHOLDER_ALT_MAPPING
  | typeof EMPTY_BOX_ALT_MAPPING;
