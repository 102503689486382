import { Dispatch } from 'redux';

import Constructed from '../../../tools/constructed/Constructed';
import ConstructedCvUser from '../../../types/business/cv/fields/user/constructed/ConstructedCvUser';
import CvUserConstructFlow from '../../../types/business/cv/fields/user/constructed/CvUserConstructFlow';

import { SET_INITIAL_CV_USER } from './cvActionTypes';

export type SetInitialCvUser = {
  type: typeof SET_INITIAL_CV_USER;
  payload: Constructed<CvUserConstructFlow, ConstructedCvUser>;
};

const setInitialCvUser =
  (cvUser: Constructed<CvUserConstructFlow, ConstructedCvUser>) =>
  (dispatch: Dispatch<SetInitialCvUser>) => {
    dispatch({ type: SET_INITIAL_CV_USER, payload: cvUser });
  };

export default setInitialCvUser;
