import { Dispatch } from 'redux';

import CvUser from '../../../types/business/cv/fields/user/CvUser';

import { SET_ACTUAL_CV_USER } from './cvActionTypes';

export type SetActualCvUser = {
  type: typeof SET_ACTUAL_CV_USER;
  payload?: CvUser;
};

const setActualCvUser =
  (cvUser?: CvUser) => (dispatch: Dispatch<SetActualCvUser>) => {
    dispatch({ type: SET_ACTUAL_CV_USER, payload: cvUser });
  };

export default setActualCvUser;
