import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';

import CvUser from '../../../types/business/cv/fields/user/CvUser';
import CvActivity from '../../../types/business/cv/fields/activity/CvActivity';

interface UseCv {
  cvUser?: CvUser;
  cvActivities?: CvActivity[];
}

const useCv = (): UseCv => {
  const { actualCvUser, actualCvActivities } = useSelector(
    (state: RootState) => state.cvReducer,
  );

  return {
    cvUser: actualCvUser,
    cvActivities: actualCvActivities,
  };
};

export default useCv;
