import { LocaleNamespace } from '../LocaleNamespace';
import { LOCALE_NAMESPACE_NAME } from '../LocaleNamespaceName';
import {
  AssetsLocaleNamespaceMappings,
  GITLAB_LOGO_ALT_MAPPING,
} from './AssetsLocaleNamespaceMappings';

export type AssetsLocaleNamespace = {
  media: {
    svg: {
      icvLogo: {
        alt: string;
      };
      gitlabLogo: {
        alt: string;
      };
      githubLogo: {
        alt: string;
      };
      linkedinLogo: {
        alt: string;
      };
      telegramLogo: {
        alt: string;
      };
      avatarPlaceholder: {
        alt: string;
      };
      emptyBox: {
        alt: string;
      };
    };
  };
};

export const ASSETS_LOCALE_NAMESPACE: LocaleNamespace<AssetsLocaleNamespaceMappings> =
  {
    name: LOCALE_NAMESPACE_NAME.ASSETS,
    mappings: GITLAB_LOGO_ALT_MAPPING,
  };
