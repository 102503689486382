import { LocaleNamespace } from '../LocaleNamespace';
import { LOCALE_NAMESPACE_NAME } from '../LocaleNamespaceName';
import {
  DATES_PRESENT_MAPPING,
  TimedateLocaleNamespaceMappings,
} from './TimedateLocaleNamespaceMappings';

export type TimedateLocaleNamespace = {
  dates: {
    present: string;
  };
};

export const TIMEDATE_LOCALE_NAMESPACE: LocaleNamespace<TimedateLocaleNamespaceMappings> =
  {
    name: LOCALE_NAMESPACE_NAME.TIMEDATE,
    mappings: DATES_PRESENT_MAPPING,
  };
