import React from 'react';

import { Button, ButtonProps } from '@mui/material';

const ButtonAtom: React.FC<ButtonProps> = ({
  className,
  id,
  component = 'button',
  type,
  variant,
  size,
  fullWidth = false,
  color,
  disabled = false,
  sx,
  startIcon,
  endIcon,
  children,
  onClick,
  onTouchStart,
}) => (
  <Button
    className={className}
    id={id}
    component={component}
    type={type}
    variant={variant}
    size={size}
    fullWidth={fullWidth}
    color={color}
    disabled={disabled}
    sx={sx}
    startIcon={startIcon}
    endIcon={endIcon}
    onClick={onClick}
    onTouchStart={onTouchStart}>
    {children}
  </Button>
);

export default ButtonAtom;
