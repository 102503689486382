import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';

import useStaticResourcesLoader from '../../resources/useStaticResourcesLoader';
import useStaticResources from '../../resources/useStaticResources';
import isConstructionFinished from '../../../tools/constructed/utils/isConstructionFinished';
import CvActivity from '../../../types/business/cv/fields/activity/CvActivity';
import toCvActivities from '../../../types/business/cv/fields/activity/constructed/converters/toCvActivities';

interface UseCvLoader {
  loadCv: () => void;
  isCvUserLoaded: boolean;
  isCvActivitiesLoaded: boolean;
  initialCvActivities: CvActivity[];
}

/**
 * @see useCvLoaderEffects - Receiver of the useCvLoader events
 */
const useCvLoader = (): UseCvLoader => {
  const { loadCvResource } = useStaticResourcesLoader();
  const { cv: cvResource } = useStaticResources();
  const { initialCvUser, initialCvActivities } = useSelector(
    (state: RootState) => state.cvReducer,
  );

  // Initial point of loading
  const loadCv = () => {
    if (!cvResource) {
      loadCvResource();
    }
  };

  // Other
  const isCvUserLoaded = useMemo(
    () => isConstructionFinished(initialCvUser),
    [initialCvUser],
  );

  const isCvActivitiesLoaded = useMemo(
    () => isConstructionFinished(initialCvActivities),
    [initialCvActivities],
  );

  const initialCvActivitiesValue = useMemo(
    () => toCvActivities(initialCvActivities.value) ?? [],
    [initialCvActivities.value],
  );

  return {
    loadCv,
    isCvUserLoaded,
    isCvActivitiesLoaded,
    initialCvActivities: initialCvActivitiesValue,
  };
};

export default useCvLoader;
