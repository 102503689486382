import InternalNavEndpoint from '../../tools/navigation/endpoints/InternalNavEndpoint';

import { INTERNAL_NAVIGATION_SCHEME } from '../../tools/navigation/internalNavigationScheme';

interface UseInternalNavigation {
  toRoot(): InternalNavEndpoint;
}

const useInternalNavigation = (): UseInternalNavigation => {
  const toRoot = () => INTERNAL_NAVIGATION_SCHEME.root;

  return {
    toRoot,
  };
};

export default useInternalNavigation;
