import ExternalNavEndpoint from '../../tools/navigation/endpoints/ExternalNavEndpoint';
import buildUrlQuery from '../../tools/web/utils/buildUrlQuery';
import UrlPath from '../../tools/web/url/paths/UrlPath';

import GoogleMapsSearch from '../../tools/geolocation/mappings/google/search/GoogleMapsSearch';
import googleMapsSearchToQuery from '../../tools/geolocation/mappings/google/search/util/googleMapsSearchToQuery';

import { EXTERNAL_NAVIGATION_SCHEME } from '../../tools/navigation/externalNavigationScheme';

interface UseExternalNavigation {
  toIcvGitLabRepo(): ExternalNavEndpoint;

  toIcvGitLabRepoIssues(): ExternalNavEndpoint;

  toCreatorsWebsite(): ExternalNavEndpoint;

  toGoogleMapsSearch(search: GoogleMapsSearch): ExternalNavEndpoint;

  toLinkedInUser(path: UrlPath): ExternalNavEndpoint;

  toGitLab(path: UrlPath): ExternalNavEndpoint;

  toGitHub(path: UrlPath): ExternalNavEndpoint;
}

const useExternalNavigation = (): UseExternalNavigation => {
  const toIcvGitLabRepo = () => EXTERNAL_NAVIGATION_SCHEME.icvGitlabRepo;

  const toIcvGitLabRepoIssues = () =>
    EXTERNAL_NAVIGATION_SCHEME.icvGitlabRepoIssues;

  const toCreatorsWebsite = () => EXTERNAL_NAVIGATION_SCHEME.creatorsWebsite;

  const toGoogleMapsSearch = (googleMapsSearch: GoogleMapsSearch) => {
    if (!googleMapsSearch) {
      return EXTERNAL_NAVIGATION_SCHEME.googleMapsSearch;
    }

    const googleMapsEndpoint = EXTERNAL_NAVIGATION_SCHEME.googleMapsSearch;
    const googleMapsSearchQuery = googleMapsSearchToQuery(googleMapsSearch);
    if (googleMapsSearchQuery) {
      googleMapsEndpoint.query = buildUrlQuery(googleMapsSearchQuery);
    }
    return googleMapsEndpoint;
  };

  const toLinkedInUser = (path: UrlPath): ExternalNavEndpoint => ({
    ...EXTERNAL_NAVIGATION_SCHEME.linkedinUser,
    path,
  });

  const toGitLab = (path: UrlPath): ExternalNavEndpoint => ({
    ...EXTERNAL_NAVIGATION_SCHEME.gitlab,
    path,
  });

  const toGitHub = (path: UrlPath): ExternalNavEndpoint => ({
    ...EXTERNAL_NAVIGATION_SCHEME.github,
    path,
  });

  return {
    toIcvGitLabRepo,
    toIcvGitLabRepoIssues,
    toCreatorsWebsite,
    toGoogleMapsSearch,
    toLinkedInUser,
    toGitLab,
    toGitHub,
  };
};

export default useExternalNavigation;
