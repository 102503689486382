import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Button, ButtonProps } from '@mui/material';

import buildUrlFromEndpoint from '../../../../../tools/navigation/utils/buildUrlFromEndpoint';
import ExternalNavEndpoint from '../../../../../tools/navigation/endpoints/ExternalNavEndpoint';
import InternalNavEndpoint from '../../../../../tools/navigation/endpoints/InternalNavEndpoint';

export type LinkButtonAtomProps = ButtonProps & {
  to: ExternalNavEndpoint | InternalNavEndpoint;
  replace?: boolean;
};

const LinkButtonAtom: React.FC<LinkButtonAtomProps> = ({
  className,
  id,
  variant,
  children,
  to,
  replace = false,
}) => (
  <Button
    className={className}
    id={id}
    variant={variant}
    component={RouterLink}
    replace={replace}
    to={buildUrlFromEndpoint(to)}>
    {children}
  </Button>
);

export default LinkButtonAtom;
