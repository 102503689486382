import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';
import useRootDispatch from '../../../storage/dispatch/rootDispatch';
import setInitialCvUser from '../../../storage/actions/cv/setInitialCvUser';
import setActualCvUser from '../../../storage/actions/cv/setActualCvUser';
import setInitialCvActivities from '../../../storage/actions/cv/setInitialCvActivities';
import setActualCvActivities from '../../../storage/actions/cv/setActualCvActivities';

import useStaticResourcesLoader from '../../resources/useStaticResourcesLoader';
import useStaticResources from '../../resources/useStaticResources';

import ConstructFlow from '../../../tools/constructed/ConstructFlow';
import ConstructStage from '../../../tools/constructed/ConstructStage';
import removeStagesFromFlow from '../../../tools/constructed/utils/removeStagesFromFlow';
import wasStageRequirementsFulfilled from '../../../tools/constructed/utils/wasStageRequirementsFulfilled';
import isStageExistsInFlow from '../../../tools/constructed/utils/isStageExistsInFlow';

import toCvUser from '../../../types/business/cv/fields/user/constructed/converters/toCvUser';
import toCvActivities from '../../../types/business/cv/fields/activity/constructed/converters/toCvActivities';
import createConstructedUserFromResource from '../../../types/business/cv/fields/user/constructed/operations/createConstructedUserFromResource';
import updateConstructedUserWithPositionResource from '../../../types/business/cv/fields/user/constructed/operations/updateConstructedUserWithPositionResource';
import createConstructedActivityFromResource from '../../../types/business/cv/fields/activity/constructed/operations/createConstructedActivityFromResource';
import updateConstructedActivityWithPositionResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithPositionResource';
import updateConstructedActivityWithProjectResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithProjectResource';
import updateConstructedActivityWithProviderResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithProviderResource';
import updateConstructedActivityWithSubactivitiesResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithSubactivitiesResource';
import updateConstructedActivityWithSkillsResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithSkillsResource';

import { CV_USER_BASE_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/user/constructed/stages/CvUserBaseConstructStage';
import { CV_USER_POSITION_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/user/constructed/stages/CvUserPositionConstructStage';
import { CV_ACTIVITY_BASE_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivityBaseConstructStage';
import { CV_ACTIVITY_POSITION_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivityPositionConstructStage';
import { CV_ACTIVITY_PROJECT_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivityProjectConstructStage';
import { CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivityProviderConstructStage';
import { CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivitySubactivitiesConstructStage';
import { CV_ACTIVITY_SKILLS_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivitySkillsConstructStage';

/**
 * @see useCvLoader - Producer of the useCvLoaderEffects events
 */
const useCvLoaderEffects = () => {
  const dispatch = useRootDispatch();
  const {
    loadUserResource,
    loadActivitiesResource,
    loadSubactivitiesResource,
    loadProjectsResource,
    loadProvidersResource,
    loadPositionsResource,
    loadSkillsResource,
  } = useStaticResourcesLoader();
  const {
    cv: cvResource,
    user: userResource,
    activities: activitiesResource,
    subactivities: subactivitiesResource,
    projects: projectsResource,
    providers: providersResource,
    positions: positionsResource,
    skills: skillsResource,
  } = useStaticResources();
  const { initialCvUser, initialCvActivities } = useSelector(
    (state: RootState) => state.cvReducer,
  );

  // Load missing static resources

  useEffect(() => {
    if (cvResource && !userResource) {
      loadUserResource();
    }
  }, [cvResource, userResource, loadUserResource]);

  useEffect(() => {
    if (cvResource && !activitiesResource) {
      loadActivitiesResource();
    }
  }, [cvResource, activitiesResource, loadActivitiesResource]);

  useEffect(() => {
    if (activitiesResource && !projectsResource) {
      loadProjectsResource();
    }
  }, [activitiesResource, projectsResource, loadProjectsResource]);

  useEffect(() => {
    if (activitiesResource && !providersResource) {
      loadProvidersResource();
    }
  }, [activitiesResource, providersResource, loadProvidersResource]);

  useEffect(() => {
    if (activitiesResource && !positionsResource) {
      loadPositionsResource();
    }
  }, [activitiesResource, positionsResource, loadPositionsResource]);

  useEffect(() => {
    if (activitiesResource && !subactivitiesResource) {
      loadSubactivitiesResource();
    }
  }, [activitiesResource, subactivitiesResource, loadSubactivitiesResource]);

  useEffect(() => {
    if (positionsResource && !skillsResource) {
      loadSkillsResource();
    }
  }, [positionsResource, skillsResource, loadSkillsResource]);

  const onStage = <T, R extends T>(
    stage: ConstructStage<T, R>,
    flow: ConstructFlow<T, R>,
    callback: () => void,
  ) => {
    if (
      isStageExistsInFlow(stage, flow) &&
      wasStageRequirementsFulfilled(stage, flow)
    ) {
      callback();
    }
  };

  // Initial CV user live loading and updating

  useEffect(() => {
    onStage(CV_USER_BASE_CONSTRUCT_STAGE, initialCvUser.flow, () => {
      if (userResource) {
        dispatch(
          setInitialCvUser({
            flow: removeStagesFromFlow(
              [CV_USER_BASE_CONSTRUCT_STAGE],
              initialCvUser.flow,
            ),
            value: createConstructedUserFromResource(userResource),
          }),
        );
      }
    });
  }, [initialCvUser.flow, userResource, dispatch]);

  useEffect(() => {
    onStage(CV_USER_POSITION_CONSTRUCT_STAGE, initialCvUser.flow, () => {
      if (initialCvUser.value && positionsResource) {
        dispatch(
          setInitialCvUser({
            flow: removeStagesFromFlow(
              [CV_USER_POSITION_CONSTRUCT_STAGE],
              initialCvUser.flow,
            ),
            value: updateConstructedUserWithPositionResource(
              initialCvUser.value,
              positionsResource,
            ),
          }),
        );
      }
    });
  }, [initialCvUser, positionsResource, dispatch]);

  useEffect(() => {
    dispatch(setActualCvUser(toCvUser(initialCvUser.value)));
  }, [initialCvUser.value, dispatch]);

  // Initial CV activities live loading and updating

  useEffect(() => {
    onStage(CV_ACTIVITY_BASE_CONSTRUCT_STAGE, initialCvActivities.flow, () => {
      if (activitiesResource) {
        dispatch(
          setInitialCvActivities({
            flow: removeStagesFromFlow(
              [CV_ACTIVITY_BASE_CONSTRUCT_STAGE],
              initialCvActivities.flow,
            ),
            value: activitiesResource.map((activity) =>
              createConstructedActivityFromResource(activity),
            ),
          }),
        );
      }
    });
  }, [initialCvActivities.flow, activitiesResource, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_POSITION_CONSTRUCT_STAGE,
      initialCvActivities.flow,
      () => {
        if (initialCvActivities.value && positionsResource) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_POSITION_CONSTRUCT_STAGE],
                initialCvActivities.flow,
              ),
              value: initialCvActivities.value.map((activity) =>
                updateConstructedActivityWithPositionResource(
                  activity,
                  positionsResource,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [initialCvActivities, positionsResource, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_PROJECT_CONSTRUCT_STAGE,
      initialCvActivities.flow,
      () => {
        if (initialCvActivities.value && projectsResource) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_PROJECT_CONSTRUCT_STAGE],
                initialCvActivities.flow,
              ),
              value: initialCvActivities.value.map((activity) =>
                updateConstructedActivityWithProjectResource(
                  activity,
                  projectsResource,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [initialCvActivities, projectsResource, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE,
      initialCvActivities.flow,
      () => {
        if (initialCvActivities.value && providersResource) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE],
                initialCvActivities.flow,
              ),
              value: initialCvActivities.value.map((activity) =>
                updateConstructedActivityWithProviderResource(
                  activity,
                  providersResource,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [initialCvActivities, providersResource, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE,
      initialCvActivities.flow,
      () => {
        if (initialCvActivities.value && subactivitiesResource) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE],
                initialCvActivities.flow,
              ),
              value: initialCvActivities.value.map((activity) =>
                updateConstructedActivityWithSubactivitiesResource(
                  activity,
                  subactivitiesResource,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [initialCvActivities, subactivitiesResource, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_SKILLS_CONSTRUCT_STAGE,
      initialCvActivities.flow,
      () => {
        if (initialCvActivities.value && skillsResource) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_SKILLS_CONSTRUCT_STAGE],
                initialCvActivities.flow,
              ),
              value: initialCvActivities.value.map((activity) =>
                updateConstructedActivityWithSkillsResource(
                  activity,
                  skillsResource,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [initialCvActivities, skillsResource, dispatch]);

  useEffect(() => {
    dispatch(setActualCvActivities(toCvActivities(initialCvActivities.value)));
  }, [initialCvActivities.value, dispatch]);
};

export default useCvLoaderEffects;
