import { Unit } from 'humanize-duration';

type HumanizeDurationFormat = {
  units: Unit[];
  round?: boolean;
  largest?: number;
};

export const Y_DURATION_FORMAT: HumanizeDurationFormat = {
  units: ['y'],
  round: true,
};

export const Y_MO_D_DURATION_FORMAT: HumanizeDurationFormat = {
  units: ['y', 'mo', 'd'],
  round: true,
};

export const Y_MO_D_LARGEST_DURATION_FORMAT: HumanizeDurationFormat = {
  units: ['y', 'mo', 'd'],
  round: true,
  largest: 1,
};

export const Y_MO_D_TWO_LARGEST_DURATION_FORMAT: HumanizeDurationFormat = {
  units: ['y', 'mo', 'd'],
  round: true,
  largest: 2,
};

type HumanizeDurationFormats =
  | typeof Y_DURATION_FORMAT
  | typeof Y_MO_D_DURATION_FORMAT
  | typeof Y_MO_D_LARGEST_DURATION_FORMAT
  | typeof Y_MO_D_TWO_LARGEST_DURATION_FORMAT;

export default HumanizeDurationFormats;
