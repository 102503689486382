import React from 'react';
import { FormControl, FormGroup } from '@mui/material';

type CheckboxGroupAtomProps = {
  className?: string;
  row?: boolean;
  children: React.ReactNode;
};

const CheckboxGroupAtom: React.FC<CheckboxGroupAtomProps> = ({
  className,
  row,
  children,
}) => (
  <FormControl className={`${className} form-control`} component='fieldset'>
    <FormGroup className='form-control__group' row={row}>
      {children}
    </FormGroup>
  </FormControl>
);

export default CheckboxGroupAtom;
