import ConstructFlow from '../ConstructFlow';
import ConstructStage from '../ConstructStage';

const removeStagesFromFlow = <T, R>(
  stagesToRemove: ConstructStage<T, R>[],
  flow: ConstructFlow<T, R>,
): ConstructFlow<T, R> =>
  flow.filter(
    (stageFromFlow) =>
      !stagesToRemove.some(
        (stageToComplete) => stageToComplete.name === stageFromFlow.name,
      ),
  );

export default removeStagesFromFlow;
