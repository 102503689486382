import {
  CARD_ACTIVITY_TYPE_COMMERCIAL_MAPPING,
  CARD_ACTIVITY_TYPE_EDUCATIONAL_MAPPING,
  CARD_ACTIVITY_TYPE_INTERNSHIP_MAPPING,
  CARD_ACTIVITY_TYPE_PERSONAL_MAPPING,
  CARD_ACTIVITY_TYPE_STARTUP_MAPPING,
} from '../MoleculesLocaleNamespaceMappings';
import ActivityType, {
  COMMERCIAL_ACTIVITY_TYPE,
  INTERNSHIP_ACTIVITY_TYPE,
  PERSONAL_ACTIVITY_TYPE,
  STARTUP_ACTIVITY_TYPE,
} from '../../../../../../../types/business/activity/ActivityType';

const mapActivityTypeToLocaleMapping = (
  activityType: ActivityType,
):
  | typeof CARD_ACTIVITY_TYPE_PERSONAL_MAPPING
  | typeof CARD_ACTIVITY_TYPE_COMMERCIAL_MAPPING
  | typeof CARD_ACTIVITY_TYPE_STARTUP_MAPPING
  | typeof CARD_ACTIVITY_TYPE_INTERNSHIP_MAPPING
  | typeof CARD_ACTIVITY_TYPE_EDUCATIONAL_MAPPING => {
  if (activityType === PERSONAL_ACTIVITY_TYPE) {
    return CARD_ACTIVITY_TYPE_PERSONAL_MAPPING;
  } else if (activityType === COMMERCIAL_ACTIVITY_TYPE) {
    return CARD_ACTIVITY_TYPE_COMMERCIAL_MAPPING;
  } else if (activityType === STARTUP_ACTIVITY_TYPE) {
    return CARD_ACTIVITY_TYPE_STARTUP_MAPPING;
  } else if (activityType === INTERNSHIP_ACTIVITY_TYPE) {
    return CARD_ACTIVITY_TYPE_INTERNSHIP_MAPPING;
  } else {
    return CARD_ACTIVITY_TYPE_EDUCATIONAL_MAPPING;
  }
};

export default mapActivityTypeToLocaleMapping;
