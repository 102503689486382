import DemandingConstructStage from '../../../../../../../tools/constructed/stages/DemandingConstructStage';
import {
  CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME,
  CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE_NAME,
} from '../CvActivitiesConstructStageName';

type CvActivitySubactivitiesConstructStage = DemandingConstructStage<
  typeof CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE_NAME,
  typeof CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME
>;

export const CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE: CvActivitySubactivitiesConstructStage =
  {
    name: CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE_NAME,
    require: [CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME],
  };

export default CvActivitySubactivitiesConstructStage;
