import GoogleMapsSearch from '../GoogleMapsSearch';
import PlaceGoogleMapsSearch from '../PlaceGoogleMapsSearch';

const isPlaceGoogleMapsSearch = (
  googleMapsSearch: GoogleMapsSearch,
): googleMapsSearch is PlaceGoogleMapsSearch =>
  (googleMapsSearch as PlaceGoogleMapsSearch).latitude !== undefined &&
  (googleMapsSearch as PlaceGoogleMapsSearch).longitude !== undefined &&
  (googleMapsSearch as PlaceGoogleMapsSearch).placeId !== undefined;

export default isPlaceGoogleMapsSearch;
