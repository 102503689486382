import ConstructedCvActivity from '../ConstructedCvActivity';
import SkillsResource from '../../../../../../../tools/resources/types/business/skills/SkillsResource';
import ConstructedCvActivitySubactivity from '../../fields/subactivities/constructed/ConstructedCvActivitySubactivity';
import updateConstructedActivitySubactivityWithSkillResource from '../../fields/subactivities/constructed/operations/updateConstructedActivitySubactivityWithSkillResource';

const updateConstructedActivityWithSkillsResource = (
  constructedActivity: ConstructedCvActivity,
  skillsResource: SkillsResource,
): ConstructedCvActivity => ({
  ...constructedActivity,
  subactivities: ((): ConstructedCvActivitySubactivity[] | undefined => {
    if (constructedActivity.subactivities?.length === 0) {
      return [];
    }

    const constructedSubactivitiesBuffer: ConstructedCvActivitySubactivity[] =
      [];

    constructedActivity.subactivities?.forEach((constructedSubactivity) => {
      constructedSubactivitiesBuffer.push(
        updateConstructedActivitySubactivityWithSkillResource(
          constructedSubactivity as ConstructedCvActivitySubactivity,
          skillsResource,
        ),
      );
    });

    if (constructedSubactivitiesBuffer.length === 0) {
      return undefined;
    }
    return constructedSubactivitiesBuffer;
  })(),
});

export default updateConstructedActivityWithSkillsResource;
