import { ComponentProps } from '../../../ComponentProps';
import SkeletonAtom from '../../../atoms/skeletons/SkeletonAtom';
import React from 'react';

type LoadingBackgroundMoleculeProps = ComponentProps & {
  width: string;
  height: string;
};

const LoadingBackgroundMolecule: React.FC<LoadingBackgroundMoleculeProps> = ({
  className,
  width,
  height,
}) => (
  <SkeletonAtom
    className={`${className} loading-background`}
    variant='rounded'
    width={width}
    height={height}
  />
);

export default LoadingBackgroundMolecule;
