export const TEXT_RESOURCE = 'text';
export const JSON_RESOURCE = 'json';
export const MEDIA_RESOURCE = 'media';

type FileResourceExtension =
  | typeof TEXT_RESOURCE
  | typeof JSON_RESOURCE
  | typeof MEDIA_RESOURCE;

export default FileResourceExtension;
