import loadResource, { LoadResource } from '../loadResource';

import I18nLanguage from '../../../../tools/internationalization/i18n/types/I18nLanguage';
import SubactivitiesResource from '../../../../tools/resources/types/business/subactivities/SubactivitiesResource';

import {
  LOAD_SUBACTIVITIES_RESOURCE_ERROR,
  LOAD_SUBACTIVITIES_RESOURCE_REQUEST,
  LOAD_SUBACTIVITIES_RESOURCE_RESPONSE,
} from '../resourceActionTypes';
import RESOURCES_API from '../../../../tools/api/resources';

export type LoadSubactivitiesResource = LoadResource<
  typeof LOAD_SUBACTIVITIES_RESOURCE_REQUEST,
  typeof LOAD_SUBACTIVITIES_RESOURCE_RESPONSE,
  typeof LOAD_SUBACTIVITIES_RESOURCE_ERROR,
  SubactivitiesResource
>;

const loadSubactivitiesResource = (language: I18nLanguage) =>
  loadResource(
    LOAD_SUBACTIVITIES_RESOURCE_REQUEST,
    LOAD_SUBACTIVITIES_RESOURCE_RESPONSE,
    LOAD_SUBACTIVITIES_RESOURCE_ERROR,
    () => RESOURCES_API.getSubactivities(language),
  );

export default loadSubactivitiesResource;
