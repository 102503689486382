import Filter from '../../../../types/business/filter/common/Filter';
import FilterData from '../../../../types/business/filter/data/FilterData';
import FilterId from '../../../../types/business/filter/common/prototype/fields/FilterId';

const generateFilters = (filterData: FilterData[]): Filter[] => {
  const newFilters: Filter[] = [];
  let filterId: FilterId = 0;

  filterData.forEach((filterValue) => {
    filterId += 1;
    newFilters.push({
      id: filterId,
      type: filterValue.type,
      data: filterValue.data,
    } as Filter);
  });

  return newFilters;
};

export default generateFilters;
