import CvSubactivitySkill from '../CvSubactivitySkill';
import isObject from '../../../../../../../../../common/guards/isObject';

const isCvSubactivitySkill = (
  cvSubactivitySkill: unknown,
): cvSubactivitySkill is CvSubactivitySkill =>
  isObject(cvSubactivitySkill) &&
  (cvSubactivitySkill as CvSubactivitySkill).id !== undefined &&
  (cvSubactivitySkill as CvSubactivitySkill).name !== undefined;

export default isCvSubactivitySkill;
