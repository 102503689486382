import { Breakpoint } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';

import useAppTheme from './useAppTheme';

/**
 * Taken from https://material-ui.com/components/use-media-query/#migrating-from-withwidth
 *
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
type BreakpointType = Breakpoint | null;

const useCurrentBreakpoint = (): Breakpoint => {
  const { appTheme } = useAppTheme();
  const keys: readonly Breakpoint[] = [...appTheme.breakpoints.keys];

  return (
    keys.reduce((output: BreakpointType, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(appTheme.breakpoints.up(key));
      return matches ? key : output;
    }, null) ?? 'xs'
  );
};

export default useCurrentBreakpoint;
