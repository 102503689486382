import moment from 'moment';

import Date from '../Date';

import isPresentDate from '../guards/isPresentDate';

const fromDateToMoment = (date: Date): moment.Moment => {
  if (isPresentDate(date)) {
    return moment();
  }
  return moment({
    year: date.year,
    month: date.month,
    day: date.day,
  });
};

export default fromDateToMoment;
