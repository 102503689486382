import React from 'react';
import { Outlet } from 'react-router-dom';

import { CssBaseline, ThemeProvider } from '@mui/material';

import useAppTheme from '../../../hooks/theme/useAppTheme';
import useMountEffect from '../../../hooks/wrapped/useMountEffect';

import AppProps from '../AppProps';

const AppTheme: React.FC<AppProps> = ({ children = <Outlet /> }) => {
  const { appTheme, applySystemTheme } = useAppTheme();

  useMountEffect(() => {
    applySystemTheme();
  });

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default AppTheme;
