import ConstructedCvActivitySubactivity from '../ConstructedCvActivitySubactivity';
import isObject from '../../../../../../../../common/guards/isObject';

const isConstructedCvActivitySubactivity = (
  constructedCvActivitySubactivity: unknown,
): constructedCvActivitySubactivity is ConstructedCvActivitySubactivity =>
  isObject(constructedCvActivitySubactivity) &&
  (constructedCvActivitySubactivity as ConstructedCvActivitySubactivity).id !==
    undefined &&
  (constructedCvActivitySubactivity as ConstructedCvActivitySubactivity)
    .period !== undefined;

export default isConstructedCvActivitySubactivity;
