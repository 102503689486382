import ExternalNavEndpoint from '../endpoints/ExternalNavEndpoint';
import NavEndpoint from '../endpoints/NavEndpoint';

const isExternalNavEndpoint = (
  endpoint: NavEndpoint,
): endpoint is ExternalNavEndpoint =>
  (endpoint as ExternalNavEndpoint).protocol !== undefined &&
  (endpoint as ExternalNavEndpoint).domain !== undefined;

export default isExternalNavEndpoint;
