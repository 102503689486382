import requireResource, { RequireResource } from '../requireResource';
import { LOAD_USER_RESOURCE_REQUIREMENT } from '../resourceActionTypes';

export type RequireUserResource = RequireResource<
  typeof LOAD_USER_RESOURCE_REQUIREMENT
>;

const requireUserResource = () =>
  requireResource(LOAD_USER_RESOURCE_REQUIREMENT);

export default requireUserResource;
