import moment from 'moment';

import Duration from '../Duration';

const sumDurations = (durations: Duration[]): Duration => {
  const result = moment.duration(0);
  durations.forEach((duration) => {
    result.add(
      moment.duration({
        days: duration.days,
        months: duration.months,
        years: duration.years,
      }),
    );
  });
  return {
    days: result.days(),
    months: result.months(),
    years: result.years(),
  };
};

export default sumDurations;
