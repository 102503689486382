import CvActivitySubactivity from '../../CvActivitySubactivity';
import ConstructedCvActivitySubactivity from '../ConstructedCvActivitySubactivity';
import toCvSubactivitySkills from '../../fields/skills/constructed/converters/toCvSubactivitySkills';

const toCvActivitySubactivity = (
  constructedSubactivity: ConstructedCvActivitySubactivity,
): CvActivitySubactivity => ({
  ...constructedSubactivity,
  skills: toCvSubactivitySkills(constructedSubactivity.skills),
});

export default toCvActivitySubactivity;
