import { default as humanize } from 'humanize-duration';

import { I18N_DEFAULT_LANGUAGE } from '../i18n/i18n';
import HumanizeDurationFormats, {
  Y_MO_D_DURATION_FORMAT,
} from './HumanizeDurationFormats';
import getI18nLanguage from '../i18n/utils/getI18nLanguage';

const i18nHumanizer = humanize.humanizer({
  language: getI18nLanguage(),
  fallbacks: [I18N_DEFAULT_LANGUAGE],
});

const humanizeDuration = (
  milliseconds: number,
  format: HumanizeDurationFormats = Y_MO_D_DURATION_FORMAT,
): string => i18nHumanizer(milliseconds, format);

export default humanizeDuration;
