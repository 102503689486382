import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';
import useRootDispatch from '../../../storage/dispatch/rootDispatch';
import setFilterEntity from '../../../storage/actions/cv/setFilterEntity';
import setActualCvActivities from '../../../storage/actions/cv/setActualCvActivities';

import useCvLoader from './useCvLoader';

import Filter from '../../../types/business/filter/common/Filter';
import FilterId from '../../../types/business/filter/common/prototype/fields/FilterId';
import FilterData from '../../../types/business/filter/data/FilterData';

import generateFilters from '../../../tools/business/cv/filter/generateFilters';
import sortFilters from '../../../tools/business/cv/filter/sortFilters';
import generateAvailableFilterData from '../../../tools/business/cv/filter/generateAvailableFilterData';

interface UseCvFiltering {
  currentFilters: Filter[];
  availableFilterData: FilterData[];
  applyNewFilters: (newFilterValues: FilterData[]) => void;
  removeFilter: (filterId: FilterId) => void;
}

/**
 * @see useCvFilteringEffects - Receiver of the useCvFiltering events
 */
const useCvFiltering = (): UseCvFiltering => {
  const dispatch = useRootDispatch();
  const { initialCvActivities } = useCvLoader();
  const { filterEntity } = useSelector((state: RootState) => state.cvReducer);

  const availableFilterData = useMemo(
    () => generateAvailableFilterData(initialCvActivities),
    [initialCvActivities],
  );

  const applyNewFilters = (newFilterData: FilterData[]) => {
    dispatch(setActualCvActivities(undefined));

    const newFilters = generateFilters(newFilterData);
    const newFiltersSorted = sortFilters(newFilters);

    dispatch(
      setFilterEntity({
        filters: newFiltersSorted,
      }),
    );
  };

  const removeFilter = (filterId: FilterId) => {
    dispatch(setActualCvActivities(undefined));

    const newFilters = filterEntity.filters.filter(
      (filter) => filter.id !== filterId,
    );

    dispatch(
      setFilterEntity({
        filters: newFilters,
      }),
    );
  };

  return {
    currentFilters: filterEntity.filters,
    availableFilterData,
    applyNewFilters,
    removeFilter,
  };
};

export default useCvFiltering;
