import ConstructFlow from '../../../../../../tools/constructed/ConstructFlow';
import CvActivitiesConstructStageName, {
  CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME,
  CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE_NAME,
} from './CvActivitiesConstructStageName';
import { CV_ACTIVITY_BASE_CONSTRUCT_STAGE } from './stages/CvActivityBaseConstructStage';
import { CV_ACTIVITY_PROJECT_CONSTRUCT_STAGE } from './stages/CvActivityProjectConstructStage';
import { CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE } from './stages/CvActivityProviderConstructStage';
import { CV_ACTIVITY_POSITION_CONSTRUCT_STAGE } from './stages/CvActivityPositionConstructStage';
import { CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE } from './stages/CvActivitySubactivitiesConstructStage';
import { CV_ACTIVITY_SKILLS_CONSTRUCT_STAGE } from './stages/CvActivitySkillsConstructStage';

type CvActivitiesConstructFlow = ConstructFlow<
  CvActivitiesConstructStageName,
  | typeof CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME
  | typeof CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE_NAME
>;

export const CV_ACTIVITIES_CONSTRUCT_FLOW: CvActivitiesConstructFlow = [
  CV_ACTIVITY_BASE_CONSTRUCT_STAGE,
  CV_ACTIVITY_PROJECT_CONSTRUCT_STAGE,
  CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE,
  CV_ACTIVITY_POSITION_CONSTRUCT_STAGE,
  CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE,
  CV_ACTIVITY_SKILLS_CONSTRUCT_STAGE,
];

export default CvActivitiesConstructFlow;
