import SubactivityDescription from '../../../../../subactivity/SubactivityDescription';
import SubactivityId from '../../../../../subactivity/SubactivityId';
import SubactivityPeriod from '../../../../../subactivity/SubactivityPeriod';
import SubactivityResource from '../../../../../../../tools/resources/types/business/subactivities/SubactivityResource';
import CvSubactivitySkill from './fields/skills/CvSubactivitySkill';
import ConstructedCvActivitySubactivity from './constructed/ConstructedCvActivitySubactivity';

type CvActivitySubactivity = {
  id: SubactivityId;
  period: SubactivityPeriod;
  description?: SubactivityDescription;
  skills?: CvSubactivitySkill[];
};

export const createSubactivityFromResource = (
  subactivityResource: SubactivityResource,
): ConstructedCvActivitySubactivity => ({
  id: subactivityResource.id,
  period: subactivityResource.period,
  description: subactivityResource.description,
  skills: subactivityResource.skillsIds,
});

export default CvActivitySubactivity;
