import loadResource, { LoadResource } from '../loadResource';

import I18nLanguage from '../../../../tools/internationalization/i18n/types/I18nLanguage';
import PositionsResource from '../../../../tools/resources/types/business/positions/PositionsResource';

import {
  LOAD_POSITIONS_RESOURCE_ERROR,
  LOAD_POSITIONS_RESOURCE_REQUEST,
  LOAD_POSITIONS_RESOURCE_RESPONSE,
} from '../resourceActionTypes';
import RESOURCES_API from '../../../../tools/api/resources';

export type LoadPositionsResource = LoadResource<
  typeof LOAD_POSITIONS_RESOURCE_REQUEST,
  typeof LOAD_POSITIONS_RESOURCE_RESPONSE,
  typeof LOAD_POSITIONS_RESOURCE_ERROR,
  PositionsResource
>;

const loadPositionsResource = (language: I18nLanguage) =>
  loadResource(
    LOAD_POSITIONS_RESOURCE_REQUEST,
    LOAD_POSITIONS_RESOURCE_RESPONSE,
    LOAD_POSITIONS_RESOURCE_ERROR,
    () => RESOURCES_API.getPositions(language),
  );

export default loadPositionsResource;
