import { LocaleNamespace } from '../LocaleNamespace';
import { LOCALE_NAMESPACE_NAME } from '../LocaleNamespaceName';
import {
  AppLocaleNamespaceMappings,
  NAME_MAPPING,
} from './AppLocaleNamespaceMappings';

export type AppLocaleNamespace = {
  name: string;
};

export const APP_LOCALE_NAMESPACE: LocaleNamespace<AppLocaleNamespaceMappings> =
  {
    name: LOCALE_NAMESPACE_NAME.APP,
    mappings: NAME_MAPPING,
  };
