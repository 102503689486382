import ConstructStage from '../ConstructStage';
import ConstructFlow from '../ConstructFlow';
import isDemandingConstructStage from '../guards/isDemandingConstructStage';

const wasStageRequirementsFulfilled = <T, R extends T>(
  stage: ConstructStage<T, R>,
  flow: ConstructFlow<T, R>,
): boolean => {
  if (isDemandingConstructStage(stage)) {
    return !stage.require.some((requiredStage) =>
      flow.some((stageInBuildFlow) => stageInBuildFlow.name === requiredStage),
    );
  }
  return true;
};

export default wasStageRequirementsFulfilled;
