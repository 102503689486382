import React from 'react';

import useInternationalization from '../../../../../hooks/internationalization/useInternationalization';

import { ComponentProps } from '../../../ComponentProps';
import TypographyAtom from '../../../atoms/typography/TypographyAtom';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { TEXT_TRUNCATABLE_READ_MORE_MAPPING } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';

type TruncatableTextMoleculeProps = ComponentProps & {
  maxCharacters?: number;
  variant?:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'overline'
    | 'inherit';
  children: string;
};

const TruncatableTextMolecule: React.FC<TruncatableTextMoleculeProps> = ({
  className,
  maxCharacters = 180,
  variant,
  children,
}) => {
  const { t } = useInternationalization(MOLECULES_LOCALE_NAMESPACE);
  const [expanded, setExpanded] = React.useState(false);

  const renderTruncatedText = () => {
    if (!expanded && children.length > maxCharacters) {
      const indexOfFirstSpace = children.indexOf(' ', maxCharacters);
      const indexOfFirstDot = children.indexOf('.', maxCharacters);
      return `${children.slice(
        0,
        indexOfFirstDot < indexOfFirstSpace
          ? indexOfFirstDot
          : indexOfFirstSpace,
      )}... `;
    }
    return children;
  };

  return (
    <TypographyAtom
      className={`${className} truncatable-text`}
      variant={variant}>
      {renderTruncatedText()}
      <TypographyAtom
        className='truncatable-text__read-more'
        display={
          expanded || children.length <= maxCharacters ? 'none' : undefined
        }
        color='primary'
        cursor='pointer'
        onClick={() => setExpanded(true)}>
        {t(TEXT_TRUNCATABLE_READ_MORE_MAPPING)}
      </TypographyAtom>
    </TypographyAtom>
  );
};

export default TruncatableTextMolecule;
