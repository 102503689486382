import loadResource, { LoadResource } from '../loadResource';

import I18nLanguage from '../../../../tools/internationalization/i18n/types/I18nLanguage';
import ProvidersResource from '../../../../tools/resources/types/business/providers/ProvidersResource';

import {
  LOAD_PROVIDERS_RESOURCE_ERROR,
  LOAD_PROVIDERS_RESOURCE_REQUEST,
  LOAD_PROVIDERS_RESOURCE_RESPONSE,
} from '../resourceActionTypes';
import RESOURCES_API from '../../../../tools/api/resources';

export type LoadProvidersResource = LoadResource<
  typeof LOAD_PROVIDERS_RESOURCE_REQUEST,
  typeof LOAD_PROVIDERS_RESOURCE_RESPONSE,
  typeof LOAD_PROVIDERS_RESOURCE_ERROR,
  ProvidersResource
>;

const loadProvidersResource = (language: I18nLanguage) =>
  loadResource(
    LOAD_PROVIDERS_RESOURCE_REQUEST,
    LOAD_PROVIDERS_RESOURCE_RESPONSE,
    LOAD_PROVIDERS_RESOURCE_ERROR,
    () => RESOURCES_API.getProviders(language),
  );

export default loadProvidersResource;
