export const CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME = 'CV_ACTIVITY/BASE';
export const CV_ACTIVITY_PROJECT_CONSTRUCT_STAGE_NAME = 'CV_ACTIVITY/PROJECT';
export const CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE_NAME = 'CV_ACTIVITY/PROVIDER';
export const CV_ACTIVITY_POSITION_CONSTRUCT_STAGE_NAME = 'CV_ACTIVITY/POSITION';
export const CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE_NAME =
  'CV_ACTIVITY/SUBACTIVITIES';
export const CV_ACTIVITY_SKILLS_CONSTRUCT_STAGE_NAME = 'CV_ACTIVITY/SKILLS';

type CvActivitiesConstructStageName =
  | typeof CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME
  | typeof CV_ACTIVITY_PROJECT_CONSTRUCT_STAGE_NAME
  | typeof CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE_NAME
  | typeof CV_ACTIVITY_POSITION_CONSTRUCT_STAGE_NAME
  | typeof CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE_NAME
  | typeof CV_ACTIVITY_SKILLS_CONSTRUCT_STAGE_NAME;

export default CvActivitiesConstructStageName;
