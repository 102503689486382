import { SET_APP_THEME_MODE } from '../actions/app/appActionTypes';
import AppThemeMode, {
  DARK_THEME_MODE,
} from '../../tools/theme/app/AppThemeMode';
import { SetAppThemeMode } from '../actions/app/setAppThemeMode';

interface AppStore {
  themeMode: AppThemeMode;
}

const initialState: AppStore = {
  themeMode: DARK_THEME_MODE,
};

type AppReducerTypes = SetAppThemeMode;

export const appReducer = (
  state = initialState,
  action: AppReducerTypes,
): AppStore => {
  switch (action.type) {
    case SET_APP_THEME_MODE: {
      return {
        ...state,
        themeMode: action.payload,
      };
    }
    default:
      return state;
  }
};
