import CvActivitySubactivityId from '../../../../constructed/fields/CvActivitySubactivityId';
import CvActivitySubactivity from '../../CvActivitySubactivity';
import ConstructedCvActivitySubactivity from '../ConstructedCvActivitySubactivity';
import isConstructedCvActivitySubactivity from '../guards/isConstructedCvActivitySubactivity';
import toCvActivitySubactivity from './toCvActivitySubactivity';

const toCvActivitySubactivities = (
  subactivities?: (
    | CvActivitySubactivityId
    | ConstructedCvActivitySubactivity
  )[],
): CvActivitySubactivity[] | undefined => {
  if (subactivities?.length === 0) {
    return [];
  }

  const cvActivitySubactivities: CvActivitySubactivity[] = [];
  subactivities?.forEach((subactivity) => {
    if (isConstructedCvActivitySubactivity(subactivity)) {
      cvActivitySubactivities.push(toCvActivitySubactivity(subactivity));
    }
  });

  if (cvActivitySubactivities.length === 0) {
    return undefined;
  }
  return cvActivitySubactivities;
};

export default toCvActivitySubactivities;
