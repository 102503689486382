import React from 'react';

import { ComponentProps } from '../../../ComponentProps';
import ButtonAtom from '../../../atoms/buttons/regular/ButtonAtom';

type MoreButtonMoleculeProps = ComponentProps & {
  display: boolean;
  size?: 'small' | 'medium';
  onClick?: () => void;
  children: React.ReactNode;
};

const MoreButtonMolecule: React.FC<MoreButtonMoleculeProps> = ({
  className,
  display,
  size,
  onClick,
  children,
}) => (
  <ButtonAtom
    className={`${className} more-button`}
    variant='contained'
    size={size}
    sx={{
      display: display ? undefined : 'none',
      borderRadius: 5,
      fontSize: size === 'medium' ? `${1.5 * 0.8125}rem` : undefined,
    }}
    onClick={onClick}>
    {children}
  </ButtonAtom>
);

export default MoreButtonMolecule;
