import React from 'react';

import { Box, ClickAwayListener, ClickAwayListenerProps } from '@mui/material';

const ClickAwayListenerAtom: React.FC<ClickAwayListenerProps> = ({
  onClickAway,
  children,
}) => (
  <ClickAwayListener onClickAway={onClickAway}>
    <Box component='div'>{children}</Box>
  </ClickAwayListener>
);

export default ClickAwayListenerAtom;
