import React, { useEffect } from 'react';

import { AccordionActions, AccordionDetails, Box } from '@mui/material';

import useInternationalization from '../../../../../../hooks/internationalization/useInternationalization';

import Position from '../../../../../../types/business/position/Position';
import ActivityPositionFilterData from '../../../../../../types/business/filter/data/variants/position/ActivityPositionFilterData';

import FilterDialogMoleculeAccordionProps from './FilterDialogMoleculeAccordionProps';
import ButtonAtom from '../../../../atoms/buttons/regular/ButtonAtom';
import CheckboxAtom from '../../../../atoms/checkboxes/CheckboxAtom';
import CheckboxGroupAtom from '../../../../atoms/checkboxes/group/CheckboxGroupAtom';

import { ACTIVITY_POSITION_FILTER } from '../../../../../../types/business/filter/data/prototype/fields/FilterType';
import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { DIALOG_FILTER_ACCORDION_RESET_MAPPING } from '../../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';

type ActivityPositionFilterDialogMoleculeAccordionProps =
  FilterDialogMoleculeAccordionProps<ActivityPositionFilterData>;

const ActivityPositionFilterDialogMoleculeAccordion: React.FC<
  ActivityPositionFilterDialogMoleculeAccordionProps
> = ({ className, initialFilterData, currentFilterData, onChange }) => {
  const { t } = useInternationalization(MOLECULES_LOCALE_NAMESPACE);

  const [accordionFilterData, setAccordionFilterData] = React.useState<
    ActivityPositionFilterData[]
  >([]);

  useEffect(() => {
    setAccordionFilterData(currentFilterData);
  }, [currentFilterData]);

  const resetFilter = () => {
    setAccordionFilterData([]);
    onChange([]);
  };

  const handleClick = (position: Position) => {
    const newFilterData = [...accordionFilterData];

    const filterDataPosition = newFilterData.findIndex(
      (filterData) => filterData.data.id === position.id,
    );
    if (filterDataPosition === -1) {
      newFilterData.push({
        type: ACTIVITY_POSITION_FILTER,
        data: position,
      });
    } else {
      newFilterData.splice(filterDataPosition, 1);
    }

    setAccordionFilterData(newFilterData);
    onChange(newFilterData);
  };

  const renderCheckbox = (position: Position) => (
    <Box
      className={`${position.name}-checkbox-wrapper`}
      key={position.name}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleClick(position);
      }}>
      <CheckboxAtom
        className={`${position.name}-checkbox`}
        name={position.id}
        checked={accordionFilterData.every(
          (filterData) => filterData.data.id !== position.id,
        )}
        label={position.name}
      />
    </Box>
  );

  const renderAvailableCheckboxes = () =>
    initialFilterData.map((filterData) => renderCheckbox(filterData.data));

  return (
    <Box className={`${className} activity-position-accordion`}>
      <AccordionDetails className='activity-position-accordion__details'>
        <CheckboxGroupAtom className='details__group' row>
          {renderAvailableCheckboxes()}
        </CheckboxGroupAtom>
      </AccordionDetails>
      <AccordionActions className='activity-position-accordion__actions'>
        <ButtonAtom
          className='actions__reset'
          type='reset'
          onClick={resetFilter}
          color='error'
          sx={{ borderRadius: 4 }}>
          {t(DIALOG_FILTER_ACCORDION_RESET_MAPPING)}
        </ButtonAtom>
      </AccordionActions>
    </Box>
  );
};

export default ActivityPositionFilterDialogMoleculeAccordion;
