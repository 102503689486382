import Duration from '../../../tools/timedate/duration/Duration';

export const NO_SKILL_EXPERIENCE: SkillExperience = {
  years: 0,
  months: 0,
  days: 0,
};

type SkillExperience = Duration;

export default SkillExperience;
