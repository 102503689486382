import React from 'react';

import { Box } from '@mui/material';

import { ComponentProps } from '../../../ComponentProps';

type TransparentBackgroundMoleculeProps = ComponentProps & {
  component?: React.ElementType;
  sx?: object;
  children?: React.ReactNode;
};

const TransparentBackgroundMolecule: React.FC<
  TransparentBackgroundMoleculeProps
> = ({ className, component = 'div', sx, children }) => (
  <Box
    className={`${className} transparent-background`}
    component={component}
    sx={{ padding: '24px', ...sx }}>
    {children}
  </Box>
);

export default TransparentBackgroundMolecule;
