export const ACTIVE_CONTACTS_USER_PREFERENCE = 'active';
export const REGULAR_CONTACTS_USER_PREFERENCE = 'regular';
export const LIMITED_CONTACTS_USER_PREFERENCE = 'limited';

type ContactsUserPreference =
  | typeof ACTIVE_CONTACTS_USER_PREFERENCE
  | typeof REGULAR_CONTACTS_USER_PREFERENCE
  | typeof LIMITED_CONTACTS_USER_PREFERENCE;

export default ContactsUserPreference;
