import ProjectId from '../../../../../project/ProjectId';
import ProjectName from '../../../../../project/ProjectName';
import ProjectAvatar from '../../../../../project/ProjectAvatar';
import ProjectResource from '../../../../../../../tools/resources/types/business/projects/ProjectResource';
import ProjectLink from '../../../../../project/ProjectLink';

type CvActivityProject = {
  id: ProjectId;
  name: ProjectName;
  avatar?: ProjectAvatar;
  links?: ProjectLink[];
};

export const createProjectFromResource = (
  projectResource?: ProjectResource,
): CvActivityProject | undefined =>
  projectResource && {
    id: projectResource.id,
    name: projectResource.name,
    avatar: projectResource.avatar,
    links: projectResource.links,
  };

export default CvActivityProject;
