import requireResource, { RequireResource } from '../requireResource';
import { LOAD_PROVIDERS_RESOURCE_REQUIREMENT } from '../resourceActionTypes';

export type RequireProvidersResource = RequireResource<
  typeof LOAD_PROVIDERS_RESOURCE_REQUIREMENT
>;

const requireProvidersResource = () =>
  requireResource(LOAD_PROVIDERS_RESOURCE_REQUIREMENT);

export default requireProvidersResource;
