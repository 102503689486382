import requireResource, { RequireResource } from '../requireResource';
import { LOAD_POSITIONS_RESOURCE_REQUIREMENT } from '../resourceActionTypes';

export type RequirePositionsResource = RequireResource<
  typeof LOAD_POSITIONS_RESOURCE_REQUIREMENT
>;

const requirePositionsResource = () =>
  requireResource(LOAD_POSITIONS_RESOURCE_REQUIREMENT);

export default requirePositionsResource;
