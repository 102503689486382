import requireResource, { RequireResource } from '../requireResource';
import { LOAD_SKILLS_RESOURCE_REQUIREMENT } from '../resourceActionTypes';

export type RequireSkillsResource = RequireResource<
  typeof LOAD_SKILLS_RESOURCE_REQUIREMENT
>;

const requireSkillsResource = () =>
  requireResource(LOAD_SKILLS_RESOURCE_REQUIREMENT);

export default requireSkillsResource;
