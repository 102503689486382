import DemandingConstructStage from '../../../../../../../tools/constructed/stages/DemandingConstructStage';
import {
  CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME,
  CV_ACTIVITY_POSITION_CONSTRUCT_STAGE_NAME,
} from '../CvActivitiesConstructStageName';

type CvActivityPositionConstructStage = DemandingConstructStage<
  typeof CV_ACTIVITY_POSITION_CONSTRUCT_STAGE_NAME,
  typeof CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME
>;

export const CV_ACTIVITY_POSITION_CONSTRUCT_STAGE: CvActivityPositionConstructStage =
  {
    name: CV_ACTIVITY_POSITION_CONSTRUCT_STAGE_NAME,
    require: [CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME],
  };

export default CvActivityPositionConstructStage;
