import requireResource, { RequireResource } from '../requireResource';
import { LOAD_PROJECTS_RESOURCE_REQUIREMENT } from '../resourceActionTypes';

export type RequireProjectsResource = RequireResource<
  typeof LOAD_PROJECTS_RESOURCE_REQUIREMENT
>;

const requireProjectsResource = () =>
  requireResource(LOAD_PROJECTS_RESOURCE_REQUIREMENT);

export default requireProjectsResource;
