import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import I18nLanguage from './types/I18nLanguage';

import ru from './localization/locales/ru';
import en from './localization/locales/en';

export const I18N_DEFAULT_LANGUAGE: I18nLanguage = 'en';

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    resources: {
      ru,
      en,
    },
    fallbackLng: I18N_DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
  })
  .finally();

export default i18n;
