import Period from '../../period/Period';
import Duration from '../Duration';
import isSinglePeriod from '../../period/guards/isSinglePeriod';
import fromSinglePeriodToDuration from '../converters/fromSinglePeriodToDuration';
import sumDurations from './sumDurations';

const calculateDuration = (periods: Period): Duration => {
  if (isSinglePeriod(periods)) {
    return fromSinglePeriodToDuration(periods);
  }

  if (periods.length === 0) {
    return {
      days: 0,
      months: 0,
      years: 0,
    };
  }
  return sumDurations(periods.map(fromSinglePeriodToDuration));
};

export default calculateDuration;
