import ActivityPeriodFilterValue from '../ActivityPeriodFilterValue';
import PredefinedActivityPeriodFilterValue from '../variants/predefined/PredefinedActivityPeriodFilterValue';
import {
  ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_FIVE_YEARS_KEY,
  ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_YEAR_KEY,
} from '../variants/predefined/PredefinedActivityPeriodFilterValueKey';

const isPredefinedActivityPeriodFilterValue = (
  value: ActivityPeriodFilterValue,
): value is PredefinedActivityPeriodFilterValue =>
  value.key === ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_YEAR_KEY ||
  value.key === ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_FIVE_YEARS_KEY;

export default isPredefinedActivityPeriodFilterValue;
