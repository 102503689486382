import CvActivityProject from '../CvActivityProject';
import isObject from '../../../../../../../common/guards/isObject';

const isCvActivityProject = (
  cvActivityProject: unknown,
): cvActivityProject is CvActivityProject =>
  isObject(cvActivityProject) &&
  (cvActivityProject as CvActivityProject).id !== undefined &&
  (cvActivityProject as CvActivityProject).name !== undefined;

export default isCvActivityProject;
