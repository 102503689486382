import InternalNavEndpoint from './endpoints/InternalNavEndpoint';
import { InternalNavRule } from './rules/InternalNavRule';

type InternalNavigationScheme = {
  root: InternalNavEndpoint;
  notFound: InternalNavRule;
};

export const INTERNAL_NAVIGATION_SCHEME: InternalNavigationScheme = {
  root: {
    path: '/',
  },
  notFound: {
    rule: '*',
  },
};
