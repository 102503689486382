import React from 'react';

import { Tooltip, TooltipProps } from '@mui/material';

const TooltipAtom: React.FC<TooltipProps> = ({
  className,
  title,
  arrow,
  open,
  onClose,
  onMouseEnter,
  onMouseLeave,
  disableHoverListener,
  disableFocusListener,
  disableTouchListener,
  enterDelay,
  leaveDelay,
  children,
}) => (
  <Tooltip
    className={className}
    title={title}
    arrow={arrow}
    open={open}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    disableHoverListener={disableHoverListener}
    disableFocusListener={disableFocusListener}
    disableTouchListener={disableTouchListener}
    enterDelay={enterDelay}
    leaveDelay={leaveDelay}
    onClose={onClose}>
    {children}
  </Tooltip>
);

export default TooltipAtom;
