import UrlQueryObject from '../url/queries/UrlQueryObject';
import UrlQuery from '../url/queries/UrlQuery';

type UrlSearchParamsObj = Record<string, string>;

const stringifyRecord = (obj: UrlQueryObject): UrlSearchParamsObj => {
  const stringObject: UrlSearchParamsObj = {};

  Object.keys(obj).forEach((key) => {
    stringObject[key] = `${obj[key]}`;
  });

  return stringObject;
};

const buildUrlQuery = (query: UrlQueryObject): UrlQuery =>
  `?${new URLSearchParams(stringifyRecord(query)).toString()}`;

export default buildUrlQuery;
