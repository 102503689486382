import React from 'react';

import { Box } from '@mui/material';

import useMountEffect from '../../../hooks/wrapped/useMountEffect';
import useCvLoader from '../../../hooks/business/cv/useCvLoader';

import CvUniversalTemplate from '../../templates/cv/CvUniversalTemplate';

const CvPage: React.FC = () => {
  const { loadCv } = useCvLoader();

  useMountEffect(() => {
    loadCv();
  });

  return (
    <Box className='cv' width='100%' height='100%'>
      <CvUniversalTemplate className='cv__universal-template' />
    </Box>
  );
};

export default CvPage;
