import React from 'react';
import { Outlet } from 'react-router-dom';

import useCvLoaderEffects from '../../../hooks/business/cv/useCvLoaderEffects';
import useStaticResourcesLoaderEffects from '../../../hooks/resources/useStaticResourcesLoaderEffects';
import useCvFilteringEffects from '../../../hooks/business/cv/useCvFilteringEffects';

import AppProps from '../AppProps';

const AppEffects: React.FC<AppProps> = ({ children = <Outlet /> }) => {
  useCvLoaderEffects();
  useStaticResourcesLoaderEffects();
  useCvFilteringEffects();

  return <>{children}</>;
};

export default AppEffects;
