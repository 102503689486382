import React from 'react';

import { Box } from '@mui/material';

import useCvLoader from '../../../../../hooks/business/cv/useCvLoader';

import { ComponentProps } from '../../../ComponentProps';
import LoadingBackgroundMolecule from '../../../molecules/background/loading/LoadingBackgroundMolecule';
import TransparentBackgroundMolecule from '../../../molecules/background/transparent/TransparentBackgroundMolecule';
import FilterListMolecule from '../../../molecules/lists/filter/FilterListMolecule';

type CvFiltersOrganismProps = ComponentProps;

const CvFiltersOrganism: React.FC<CvFiltersOrganismProps> = ({
  className = 'filters-organism',
}) => {
  const { isCvActivitiesLoaded } = useCvLoader();

  const renderFilters = () => (
    <TransparentBackgroundMolecule
      className='filters-organism__background'
      sx={{ padding: 0 }}>
      <FilterListMolecule className='filters-organism__list' />
    </TransparentBackgroundMolecule>
  );

  const renderLoading = () => (
    <LoadingBackgroundMolecule
      className='filters-organism__loading'
      width='100%'
      height='50px'
    />
  );

  return (
    <Box className={`${className} filters-organism__content`}>
      {isCvActivitiesLoaded ? renderFilters() : renderLoading()}
    </Box>
  );
};

export default CvFiltersOrganism;
