import loadResource, { LoadResource } from '../loadResource';

import I18nLanguage from '../../../../tools/internationalization/i18n/types/I18nLanguage';
import ActivitiesResource from '../../../../tools/resources/types/business/activities/ActivitiesResource';

import {
  LOAD_ACTIVITIES_RESOURCE_ERROR,
  LOAD_ACTIVITIES_RESOURCE_REQUEST,
  LOAD_ACTIVITIES_RESOURCE_RESPONSE,
} from '../resourceActionTypes';
import RESOURCES_API from '../../../../tools/api/resources';

export type LoadActivitiesResource = LoadResource<
  typeof LOAD_ACTIVITIES_RESOURCE_REQUEST,
  typeof LOAD_ACTIVITIES_RESOURCE_RESPONSE,
  typeof LOAD_ACTIVITIES_RESOURCE_ERROR,
  ActivitiesResource
>;

const loadActivitiesResource = (language: I18nLanguage) =>
  loadResource(
    LOAD_ACTIVITIES_RESOURCE_REQUEST,
    LOAD_ACTIVITIES_RESOURCE_RESPONSE,
    LOAD_ACTIVITIES_RESOURCE_ERROR,
    () => RESOURCES_API.getActivities(language),
  );

export default loadActivitiesResource;
