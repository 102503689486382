import loadResource, { LoadResource } from '../loadResource';

import I18nLanguage from '../../../../tools/internationalization/i18n/types/I18nLanguage';
import SkillsResource from '../../../../tools/resources/types/business/skills/SkillsResource';

import {
  LOAD_SKILLS_RESOURCE_ERROR,
  LOAD_SKILLS_RESOURCE_REQUEST,
  LOAD_SKILLS_RESOURCE_RESPONSE,
} from '../resourceActionTypes';
import RESOURCES_API from '../../../../tools/api/resources';

export type LoadSkillsResource = LoadResource<
  typeof LOAD_SKILLS_RESOURCE_REQUEST,
  typeof LOAD_SKILLS_RESOURCE_RESPONSE,
  typeof LOAD_SKILLS_RESOURCE_ERROR,
  SkillsResource
>;

const loadSkillsResource = (language: I18nLanguage) =>
  loadResource(
    LOAD_SKILLS_RESOURCE_REQUEST,
    LOAD_SKILLS_RESOURCE_RESPONSE,
    LOAD_SKILLS_RESOURCE_ERROR,
    () => RESOURCES_API.getSkills(language),
  );

export default loadSkillsResource;
