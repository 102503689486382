import CvActivitySubactivity from '../../../../types/business/cv/fields/activity/fields/subactivities/CvActivitySubactivity';
import compareEndsOfSinglePeriods from '../../../timedate/period/utils/compareEndsOfSinglePeriods';
import deepClone from '../../../utils/deepClone';

const sortSubactivities = (subactivities: CvActivitySubactivity[]) =>
  deepClone(subactivities).sort((a, b) =>
    compareEndsOfSinglePeriods(a.period, b.period),
  );

export default sortSubactivities;
