import loadResource, { LoadResource } from '../loadResource';

import VersionResource from '../../../../tools/resources/types/business/version/VersionResource';

import {
  LOAD_VERSION_RESOURCE_ERROR,
  LOAD_VERSION_RESOURCE_REQUEST,
  LOAD_VERSION_RESOURCE_RESPONSE,
} from '../resourceActionTypes';
import RESOURCES_API from '../../../../tools/api/resources';

export type LoadVersionResource = LoadResource<
  typeof LOAD_VERSION_RESOURCE_REQUEST,
  typeof LOAD_VERSION_RESOURCE_RESPONSE,
  typeof LOAD_VERSION_RESOURCE_ERROR,
  VersionResource
>;

const loadVersionResource = () =>
  loadResource(
    LOAD_VERSION_RESOURCE_REQUEST,
    LOAD_VERSION_RESOURCE_RESPONSE,
    LOAD_VERSION_RESOURCE_ERROR,
    () => RESOURCES_API.getVersion(),
  );

export default loadVersionResource;
