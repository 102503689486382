import React from 'react';

import { Box } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';

import useInternationalization from '../../../../../hooks/internationalization/useInternationalization';
import useCv from '../../../../../hooks/business/cv/useCv';

import { ComponentProps } from '../../../ComponentProps';
import TooltipAtom from '../../../atoms/tooltips/TooltipAtom';
import IconButtonAtom from '../../../atoms/buttons/icon/IconButtonAtom';
import FilterDialogMolecule from '../../dialogs/filter/FilterDialogMolecule';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { BUTTON_FILTER_TOOLTIP_MAPPING } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';

type FilterButtonMoleculeProps = ComponentProps;

const FilterButtonMolecule: React.FC<FilterButtonMoleculeProps> = ({
  className,
}) => {
  const { t } = useInternationalization(MOLECULES_LOCALE_NAMESPACE);
  const { cvActivities } = useCv();

  const [isDialogOpened, setIsDialogOpened] = React.useState(false);

  return (
    <Box className={`${className} filter-button`}>
      <TooltipAtom
        className='filter-button__tooltip'
        title={t(BUTTON_FILTER_TOOLTIP_MAPPING)}
        arrow>
        <Box className='filter-button__tooltip-wrapper'>
          <IconButtonAtom
            className='filter-button__button'
            disabled={cvActivities === undefined}
            size='small'
            color='primary'
            onClick={() => setIsDialogOpened(true)}>
            <FilterAlt style={{ width: '36px', height: '36px' }} />
          </IconButtonAtom>
        </Box>
      </TooltipAtom>
      <FilterDialogMolecule
        className='filter-button__dialog'
        open={isDialogOpened}
        handleClose={() => setIsDialogOpened(false)}
      />
    </Box>
  );
};

export default FilterButtonMolecule;
