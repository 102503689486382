import moment from 'moment';

import Duration from '../Duration';
import SinglePeriod from '../../period/SinglePeriod';
import fromDateToMoment from '../../date/converters/fromDateToMoment';

const fromSinglePeriodToDuration = (period: SinglePeriod): Duration => {
  const start = fromDateToMoment(period.start);
  const end = fromDateToMoment(period.end);
  const result = moment.duration(end.diff(start));
  return {
    days: result.days(),
    months: result.months(),
    years: result.years(),
  };
};

export default fromSinglePeriodToDuration;
