import React from 'react';

import { Box } from '@mui/material';

import useTimedate from '../../../../../hooks/timedate/useTimedate';
import useDynamicResources from '../../../../../hooks/resources/useDynamicResources';

import SkillId from '../../../../../types/business/skill/SkillId';
import SkillExperience from '../../../../../types/business/skill/SkillExperience';
import SkillName from '../../../../../types/business/skill/SkillName';
import SkillAvatar from '../../../../../types/business/skill/SkillAvatar';

import { ComponentProps } from '../../../ComponentProps';
import ChipAtom from '../../../atoms/chips/ChipAtom';
import AvatarAtom from '../../../atoms/avatars/AvatarAtom';
import TooltipAtom from '../../../atoms/tooltips/TooltipAtom';
import ClickAwayListenerAtom from '../../../atoms/listeners/clickAway/ClickAwayListenerAtom';

import {
  CIRCULAR_IMAGE_SHAPE,
  RECTANGULAR_IMAGE_SHAPE,
  ROUNDED_IMAGE_SHAPE,
} from '../../../../../types/common/image/fields/shape/ImageCommonShape';

type ChipSkill = {
  id: SkillId;
  name: SkillName;
  experience?: SkillExperience;
  avatar?: SkillAvatar;
};

type SkillChipMoleculeProps = ComponentProps & {
  skill: ChipSkill;
  size?: 'small' | 'medium';
};

const SkillChipMolecule: React.FC<SkillChipMoleculeProps> = ({
  className,
  skill,
  size = 'small',
}) => {
  const { dui } = useTimedate();
  const { buildUrlFromImageResourceSource } = useDynamicResources();

  const [isTooltipOpened, setIsTooltipOpened] = React.useState(false);

  const handleTooltipOpen = () => {
    setIsTooltipOpened(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpened(false);
  };

  const buildAvatarSx = () => {
    if (skill.avatar) {
      if (size === 'medium') {
        switch (skill.avatar.shape) {
          case RECTANGULAR_IMAGE_SHAPE:
            return { marginLeft: 2.5 };
          case ROUNDED_IMAGE_SHAPE:
            return { marginLeft: 1.75 };
          case CIRCULAR_IMAGE_SHAPE:
          default:
            return { marginLeft: 0.75 };
        }
      }
      if (size === 'small') {
        switch (skill.avatar.shape) {
          case RECTANGULAR_IMAGE_SHAPE:
            return { marginLeft: 1.5 };
          case ROUNDED_IMAGE_SHAPE:
            return { marginLeft: 1 };
          case CIRCULAR_IMAGE_SHAPE:
          default:
            return undefined;
        }
      }
    }
    return undefined;
  };

  const renderChip = () => (
    <ChipAtom
      className={`${className} skill-chip`}
      id={skill.id}
      label={skill.name}
      variant='outlined'
      avatar={
        skill.avatar ? (
          <AvatarAtom
            src={buildUrlFromImageResourceSource(skill.avatar.src)}
            alt={skill.avatar.alt ?? skill.name}
            shape={skill.avatar.shape}
          />
        ) : undefined
      }
      avatarSx={buildAvatarSx()}
      scale={size === 'medium' ? 1.5 : 1}
    />
  );

  return skill.experience ? (
    <ClickAwayListenerAtom onClickAway={handleTooltipClose}>
      <TooltipAtom
        className='skill-chip__tooltip'
        title={dui(skill.experience)}
        arrow
        open={isTooltipOpened}
        onClose={handleTooltipClose}
        onMouseEnter={handleTooltipOpen}>
        <Box
          className='skill-chip__tooltip-wrapper'
          onClick={handleTooltipOpen}>
          {renderChip()}
        </Box>
      </TooltipAtom>
    </ClickAwayListenerAtom>
  ) : (
    renderChip()
  );
};

export default SkillChipMolecule;
