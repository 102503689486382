import Duration from '../Duration';

/**
 * Compare two durations.
 * @param a first duration;
 * @param b second duration;
 * @returns Positive if a > b or b is undefined, negative if a < b or a is undefined, 0 if a = b.
 */
const compareDurations = (
  a: Duration | undefined,
  b: Duration | undefined,
): number => {
  if (!b) {
    return 1;
  }
  if (!a) {
    return -1;
  }

  const compareYears = a.years - b.years;
  if (compareYears !== 0) {
    return compareYears;
  }
  const compareMonths = a.months - b.months;
  if (compareMonths !== 0) {
    return compareMonths;
  }
  const compareDays = a.days - b.days;
  if (compareDays !== 0) {
    return compareDays;
  }

  return 0;
};

export default compareDurations;
