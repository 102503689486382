import ConstructedCvActivity from '../ConstructedCvActivity';
import ProjectsResource from '../../../../../../../tools/resources/types/business/projects/ProjectsResource';
import CvActivityProjectId from '../fields/CvActivityProjectId';
import { createProjectFromResource } from '../../fields/project/CvActivityProject';

const updateConstructedActivityWithProjectResource = (
  constructedActivity: ConstructedCvActivity,
  projectsResource: ProjectsResource,
): ConstructedCvActivity => ({
  ...constructedActivity,
  project: createProjectFromResource(
    projectsResource.find(
      (project) =>
        project.id === (constructedActivity.project as CvActivityProjectId),
    ),
  ),
});

export default updateConstructedActivityWithProjectResource;
