import ConstructedCvActivitySubactivity from '../ConstructedCvActivitySubactivity';
import CvActivitySubactivitySkillId from '../fields/CvActivitySubactivitySkillId';
import SkillsResource from '../../../../../../../../../tools/resources/types/business/skills/SkillsResource';
import CvSubactivitySkill, {
  createSkillFromResource,
} from '../../fields/skills/CvSubactivitySkill';

const updateConstructedActivitySubactivityWithSkillResource = (
  constructedSubactivity: ConstructedCvActivitySubactivity,
  skillsResource: SkillsResource,
): ConstructedCvActivitySubactivity => {
  if (constructedSubactivity.skills?.length === 0) {
    return constructedSubactivity;
  }

  const skillsBuffer: CvSubactivitySkill[] = [];

  constructedSubactivity.skills?.forEach((skill) => {
    const foundSkillResource = skillsResource.find(
      (skillResource) =>
        (skill as CvActivitySubactivitySkillId) === skillResource.id,
    );
    if (foundSkillResource) {
      skillsBuffer.push(createSkillFromResource(foundSkillResource));
    }
  });

  if (skillsBuffer.length > 0) {
    constructedSubactivity.skills = skillsBuffer;
  } else {
    constructedSubactivity.skills = undefined;
  }

  return constructedSubactivity;
};

export default updateConstructedActivitySubactivityWithSkillResource;
