import CvUser from '../../CvUser';
import ConstructedCvUser from '../ConstructedCvUser';
import isPosition from '../../../../../position/guards/isPosition';

const toCvUser = (constructedCvUser?: ConstructedCvUser): CvUser | undefined =>
  constructedCvUser && {
    ...constructedCvUser,
    position: isPosition(constructedCvUser.position)
      ? constructedCvUser.position
      : undefined,
  };

export default toCvUser;
