import CvActivityProvider from '../CvActivityProvider';
import isObject from '../../../../../../../common/guards/isObject';

const isCvActivityProvider = (
  cvActivityProvider: unknown,
): cvActivityProvider is CvActivityProvider =>
  isObject(cvActivityProvider) &&
  (cvActivityProvider as CvActivityProvider).id !== undefined &&
  (cvActivityProvider as CvActivityProvider).name !== undefined;

export default isCvActivityProvider;
