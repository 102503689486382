import loadResource, { LoadResource } from '../loadResource';

import LanguageBundlesResource from '../../../../tools/resources/types/business/languageBundles/LanguageBundlesResource';

import {
  LOAD_LANGUAGE_BUNDLES_RESOURCE_ERROR,
  LOAD_LANGUAGE_BUNDLES_RESOURCE_REQUEST,
  LOAD_LANGUAGE_BUNDLES_RESOURCE_RESPONSE,
} from '../resourceActionTypes';
import RESOURCES_API from '../../../../tools/api/resources';

export type LoadLanguageBundlesResource = LoadResource<
  typeof LOAD_LANGUAGE_BUNDLES_RESOURCE_REQUEST,
  typeof LOAD_LANGUAGE_BUNDLES_RESOURCE_RESPONSE,
  typeof LOAD_LANGUAGE_BUNDLES_RESOURCE_ERROR,
  LanguageBundlesResource
>;

const loadLanguageBundlesResource = () =>
  loadResource(
    LOAD_LANGUAGE_BUNDLES_RESOURCE_REQUEST,
    LOAD_LANGUAGE_BUNDLES_RESOURCE_RESPONSE,
    LOAD_LANGUAGE_BUNDLES_RESOURCE_ERROR,
    () => RESOURCES_API.getLanguageBundles(),
  );

export default loadLanguageBundlesResource;
