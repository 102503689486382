import ActivityPeriodFilterValue from '../ActivityPeriodFilterValue';
import CustomActivityPeriodFilterValue from '../variants/custom/CustomActivityPeriodFilterValue';
import { ACTIVITY_PERIOD_FILTER_VALUE_CUSTOM_KEY } from '../ActivityPeriodFilterValueKey';

const isCustomActivityPeriodFilterValue = (
  value: ActivityPeriodFilterValue,
): value is CustomActivityPeriodFilterValue =>
  value.key === ACTIVITY_PERIOD_FILTER_VALUE_CUSTOM_KEY;

export default isCustomActivityPeriodFilterValue;
