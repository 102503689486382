import DirectoryResource from './object/DirectoryResource';
import { DIRECTORY_RESOURCE } from './object/fields/extension/DirectoryResourceExtension';

const ROOT_DIRECTORY: DirectoryResource = {
  type: DIRECTORY_RESOURCE,
  name: '',
  parent: undefined,
};

const RESOURCES_DIRECTORY: DirectoryResource = {
  type: DIRECTORY_RESOURCE,
  name: 'resources',
  parent: ROOT_DIRECTORY,
};

const LOCALE_DYNAMIC_DIRECTORY: DirectoryResource = {
  type: DIRECTORY_RESOURCE,
  name: undefined,
  parent: RESOURCES_DIRECTORY,
};

type ResourcesDirectories = {
  root: DirectoryResource;
  resources: DirectoryResource;
  locale: DirectoryResource;
};

const RESOURCES_DIRECTORIES: ResourcesDirectories = {
  root: ROOT_DIRECTORY,
  resources: RESOURCES_DIRECTORY,
  locale: LOCALE_DYNAMIC_DIRECTORY,
};

export default RESOURCES_DIRECTORIES;
