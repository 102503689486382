import { PositionExperience } from '../calculate/calculatePositionsExperience';

import compareDates from '../../../timedate/date/utils/compareDates';

const sortPositionsExperience = (positionExperiences: PositionExperience[]) =>
  positionExperiences
    .sort((a, b) => compareDates(a.lastExperience, b.lastExperience))
    .reverse();

export default sortPositionsExperience;
