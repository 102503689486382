import Duration from '../../duration/Duration';
import SinglePeriod from '../SinglePeriod';

import nowMoment from '../../date/utils/nowMoment';
import fromMomentToNumberDate from '../../date/converters/fromMomentToNumberDate';

import { PRESENT_DATE } from '../../date/PresentDate';

const fromDurationToSinglePeriod = (duration: Duration): SinglePeriod => {
  const startMoment = nowMoment();
  startMoment.subtract(duration.years, 'years');
  startMoment.subtract(duration.months, 'months');
  startMoment.subtract(duration.days, 'days');

  return {
    start: fromMomentToNumberDate(startMoment),
    end: PRESENT_DATE,
  };
};

export default fromDurationToSinglePeriod;
