import GoogleMapsSearch from '../GoogleMapsSearch';
import CoordinatesGoogleMapsSearch from '../CoordinatesGoogleMapsSearch';

const isCoordinatesGoogleMapsSearch = (
  googleMapsSearch: GoogleMapsSearch,
): googleMapsSearch is CoordinatesGoogleMapsSearch =>
  (googleMapsSearch as CoordinatesGoogleMapsSearch).latitude !== undefined &&
  (googleMapsSearch as CoordinatesGoogleMapsSearch).longitude !== undefined;

export default isCoordinatesGoogleMapsSearch;
