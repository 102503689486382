import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import useMountEffect from '../../../hooks/wrapped/useMountEffect';
import useExternalNavigation from '../../../hooks/navigation/useExternalNavigation';
import useStaticResources from '../../../hooks/resources/useStaticResources';
import useStaticResourcesLoader from '../../../hooks/resources/useStaticResourcesLoader';

import buildUrlFromEndpoint from '../../../tools/navigation/utils/buildUrlFromEndpoint';
import { createVersionFromResource } from '../../../types/business/version/Version';

import AppProps from '../AppProps';

const AppLogger: React.FC<AppProps> = ({ children = <Outlet /> }) => {
  const { toIcvGitLabRepoIssues } = useExternalNavigation();
  const { loadVersionResource } = useStaticResourcesLoader();
  const { version } = useStaticResources();

  // Do NOT format log message otherwise message will break.
  const logGreetings = () => {
    console.log(
      `%cWelcome to ICV!%c\n
Does this page need fixes or improvements? Open an issue to help make ICV better.

${String.fromCodePoint(0x1f4dd)} Create a new ICV issue: ${buildUrlFromEndpoint(
        toIcvGitLabRepoIssues(),
      )}
    `,
      'padding-top: 0.5em; font-size: 2em;',
      'padding-bottom: 0.5em;',
    );
  };

  useMountEffect(() => {
    logGreetings();
    loadVersionResource();
  });

  useEffect(() => {
    if (version) {
      console.log(`Running ICV version: ${createVersionFromResource(version)}`);
    }
  }, [version]);

  return <>{children}</>;
};

export default AppLogger;
