import React from 'react';

import { FormControlLabel, Radio, RadioProps } from '@mui/material';

type RadioAtomProps = RadioProps & {
  label: string;
};

const RadioAtom: React.FC<RadioAtomProps> = ({ className, value, label }) => (
  <FormControlLabel value={value} control={<Radio />} label={label} />
);

export default RadioAtom;
