export const CIRCULAR_IMAGE_SHAPE = 'circular';
export const ROUNDED_IMAGE_SHAPE = 'rounded';
export const RECTANGULAR_IMAGE_SHAPE = 'rectangular';

type ImageCommonShape =
  | typeof CIRCULAR_IMAGE_SHAPE
  | typeof ROUNDED_IMAGE_SHAPE
  | typeof RECTANGULAR_IMAGE_SHAPE;

export default ImageCommonShape;
