import { useEffect } from 'react';

const useMountEffect = (effect: () => void) => {
  useEffect(
    () => effect(),
    // DO NOT REMOVE, Calls only once on page load
    // eslint-disable-next-line
    [],
  );
};

export default useMountEffect;
