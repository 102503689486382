import ExternalNavEndpoint from './endpoints/ExternalNavEndpoint';
import { HTTPS } from '../web/url/protocols/UrlProtocol';

type ExternalNavigationScheme = {
  icvGitlabRepo: ExternalNavEndpoint;
  icvGitlabRepoIssues: ExternalNavEndpoint;
  creatorsWebsite: ExternalNavEndpoint;
  googleMapsSearch: ExternalNavEndpoint;
  linkedinUser: ExternalNavEndpoint;
  gitlab: ExternalNavEndpoint;
  github: ExternalNavEndpoint;
};

export const EXTERNAL_NAVIGATION_SCHEME: ExternalNavigationScheme = {
  icvGitlabRepo: {
    protocol: HTTPS,
    domain: 'gitlab.com',
    path: '/MrBattary/icv',
  },
  icvGitlabRepoIssues: {
    protocol: HTTPS,
    domain: 'gitlab.com',
    path: '/MrBattary/icv/-/issues',
  },
  creatorsWebsite: {
    protocol: HTTPS,
    domain: 'michaellinker.it',
  },
  googleMapsSearch: {
    protocol: HTTPS,
    domain: 'google.com',
    path: '/maps/search/',
  },
  linkedinUser: {
    protocol: HTTPS,
    domain: 'linkedin.com/in',
  },
  gitlab: {
    protocol: HTTPS,
    domain: 'gitlab.com',
  },
  github: {
    protocol: HTTPS,
    domain: 'github.com',
  },
};
