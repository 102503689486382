import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import React from 'react';

type CheckboxAtomProps = CheckboxProps & {
  name: string;
  label?: string;
};

const CheckboxAtom: React.FC<CheckboxAtomProps> = ({
  className,
  component,
  name,
  label,
  checked,
  defaultChecked,
  required,
  disabled,
  icon,
  checkedIcon,
  size,
  color,
  sx,
  onClick,
  onChange,
}) => {
  const renderCheckbox = () => (
    <Checkbox
      className={className}
      name={name}
      component={component}
      checked={checked}
      defaultChecked={defaultChecked}
      icon={icon}
      checkedIcon={checkedIcon}
      size={size}
      color={color}
      sx={sx}
      onClick={onClick}
      onChange={onChange}
    />
  );

  return (
    <FormControlLabel
      required={required}
      disabled={disabled}
      control={renderCheckbox()}
      label={label}
    />
  );
};

export default CheckboxAtom;
