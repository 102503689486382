import React, { useMemo } from 'react';

import { Box, Stack } from '@mui/material';

import useTimedate from '../../../../../hooks/timedate/useTimedate';
import useExternalNavigation from '../../../../../hooks/navigation/useExternalNavigation';
import useAssetsResources from '../../../../../hooks/resources/useAssetsResources';
import useInternationalization from '../../../../../hooks/internationalization/useInternationalization';
import useCurrentBreakpoint from '../../../../../hooks/theme/useCurrentBreakpoint';
import useDynamicResources from '../../../../../hooks/resources/useDynamicResources';

import CvActivity from '../../../../../types/business/cv/fields/activity/CvActivity';
import {
  COMMON_SOCIAL_LINK_TYPE,
  GITHUB_SOCIAL_LINK_TYPE,
  GITLAB_SOCIAL_LINK_TYPE,
} from '../../../../../types/business/social/link/SocialLinkType';

import { ComponentProps } from '../../../ComponentProps';
import AvatarAtom from '../../../atoms/avatars/AvatarAtom';
import TypographyAtom from '../../../atoms/typography/TypographyAtom';
import NavListMolecule, {
  NavListMoleculeItem,
} from '../../lists/nav/NavListMolecule';
import SkillListMolecule from '../../lists/skill/SkillListMolecule';
import TruncatableTextMolecule from '../../text/truncatable/TruncatableTextMolecule';
import PaperBackgroundMolecule from '../../background/paper/PaperBackgroundMolecule';

import calculateSkillsExperience from '../../../../../tools/business/cv/calculate/calculateSkillsExperience';
import sortSkillsExperience from '../../../../../tools/business/cv/sort/sortSkillsExperience';
import sortSubactivities from '../../../../../tools/business/cv/sort/sortSubactivities';
import mapActivityTypeToLocaleMapping from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/mappings/mapActivityTypeToLocaleMapping';
import UrlNavEndpoint from '../../../../../tools/navigation/endpoints/UrlNavEndpoint';
import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { ll_LOCALE_DATE_FORMAT } from '../../../../../tools/timedate/date/formats/LocaleDateFormat';

type ActivityCardMoleculeProps = ComponentProps & {
  activity: CvActivity;
};

const ActivityCardMolecule: React.FC<ActivityCardMoleculeProps> = ({
  className,
  activity,
}) => {
  const { dai } = useTimedate();
  const { buildUrlFromImageResourceSource } = useDynamicResources();
  const { gitLabLogo, gitHubLogo } = useAssetsResources();
  const { toGitLab, toGitHub } = useExternalNavigation();
  const { t: tM } = useInternationalization(MOLECULES_LOCALE_NAMESPACE);
  const breakpoint = useCurrentBreakpoint();

  const experience = useMemo(
    () =>
      activity
        ? sortSkillsExperience(calculateSkillsExperience([activity]))
        : [],
    [activity],
  );

  const renderActivityAvatar = () => {
    let avatarSource;
    let avatarAlt;
    let avatarShape;
    if (activity.project) {
      if (activity.project.avatar) {
        avatarSource = activity.project.avatar.src;
        avatarAlt = activity.project.avatar.alt;
        avatarShape = activity.project.avatar.shape;
      } else {
        avatarAlt = activity.project.name;
      }
    } else if (activity.provider) {
      if (activity.provider.avatar) {
        avatarSource = activity.provider.avatar.src;
        avatarAlt = activity.provider.avatar.alt;
        avatarShape = activity.provider.avatar.shape;
      } else {
        avatarAlt = activity.provider.name;
      }
    }

    return (
      <AvatarAtom
        className='activity-card__avatar'
        src={
          avatarSource
            ? buildUrlFromImageResourceSource(avatarSource)
            : undefined
        }
        alt={avatarAlt}
        shape={avatarShape}
        sx={{ width: '80px', height: '80px', fontSize: '250%' }}
      />
    );
  };

  const renderHeader = () => {
    const activityTypeInternationalizationMapping =
      mapActivityTypeToLocaleMapping(activity.type);

    return (
      <Stack
        className='activity-card__header'
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        spacing={1}>
        {renderActivityAvatar()}
        <Stack
          className='activity-card__header-container'
          direction='column'
          spacing={1}>
          <TypographyAtom
            className='activity-card__period'
            variant='subtitle1'
            align='right'>
            {`${dai(activity.period.start, ll_LOCALE_DATE_FORMAT)} 
        - ${dai(activity.period.end, ll_LOCALE_DATE_FORMAT)}`}
          </TypographyAtom>
          {activityTypeInternationalizationMapping ? (
            <TypographyAtom
              className='activity-card__type'
              variant='subtitle1'
              align='right'>
              {tM(activityTypeInternationalizationMapping)}
            </TypographyAtom>
          ) : null}
        </Stack>
      </Stack>
    );
  };

  const renderHeading = () => (
    <Stack className='activity-card__project-provider' direction='column'>
      <TypographyAtom
        className='activity-card__title'
        component='h4'
        variant='h4'>
        {activity.project ? activity.project.name : activity.provider?.name}
      </TypographyAtom>
      {activity.project && activity.provider && (
        <TypographyAtom
          className='activity-card__provider'
          component='h5'
          variant='h5'>
          {activity.provider?.name}
        </TypographyAtom>
      )}
      <TypographyAtom
        className='activity-card__position'
        component='h6'
        variant='h6'>
        {activity.position?.name}
      </TypographyAtom>
    </Stack>
  );

  const getDescriptionLength = () => {
    switch (breakpoint) {
      case 'xs':
        return 120;
      case 'sm':
        return 160;
      default:
        return 200;
    }
  };

  const renderDescription = () => {
    if (activity.subactivities) {
      const WHITESPACE = ' ';
      const isAnyDescriptionExists = activity.subactivities.some(
        (subactivity) => subactivity.description,
      );
      if (isAnyDescriptionExists) {
        return (
          <TruncatableTextMolecule
            className='activity-card__project-description'
            maxCharacters={getDescriptionLength()}
            variant='body1'>
            {sortSubactivities(activity.subactivities)
              .map((subactivity) => subactivity.description)
              .join(WHITESPACE)}
          </TruncatableTextMolecule>
        );
      }
      return null;
    }
  };

  const getSkillsLength = () => {
    switch (breakpoint) {
      case 'xs':
        return 4;
      case 'sm':
        return 6;
      default:
        return 8;
    }
  };

  const renderSkills = () => {
    if (activity.subactivities) {
      return (
        <SkillListMolecule
          className='activity-card__skills'
          skills={experience}
          maxSkills={getSkillsLength()}
        />
      );
    }
  };

  const renderNavLinks = () => {
    const navLinks: NavListMoleculeItem[] = [];

    if (activity.provider && activity.provider.link) {
      navLinks.push({
        image: {
          src: activity.provider.avatar?.src,
          alt: activity.provider.avatar?.alt ?? activity.provider.name,
          shape: activity.provider.avatar?.shape,
        },
        to: { url: activity.provider.link.url } as UrlNavEndpoint,
      });
    }

    if (activity.project && activity.project.links) {
      activity.project.links.forEach((link) => {
        if (activity.project && link.type === COMMON_SOCIAL_LINK_TYPE) {
          navLinks.push({
            image: {
              src: activity.project.avatar?.src,
              alt: activity.project.avatar?.alt ?? activity.project.name,
              shape: activity.project.avatar?.shape,
            },
            to: { url: link.url } as UrlNavEndpoint,
          });
        }
        if (link.type === GITLAB_SOCIAL_LINK_TYPE) {
          navLinks.push({
            image: gitLabLogo,
            to: toGitLab(link.path),
          });
        }
        if (link.type === GITHUB_SOCIAL_LINK_TYPE) {
          navLinks.push({
            image: gitHubLogo,
            to: toGitHub(link.path),
          });
        }
      });
    }

    if (navLinks.length > 0) {
      return (
        <Box
          className='activity-card__nav-wrapper'
          display='flex'
          justifyContent='flex-end'>
          <NavListMolecule
            className='activity-card__nav'
            navs={navLinks}
            size='medium'
            badge
          />
        </Box>
      );
    }
    return null;
  };

  return (
    <Box className={`${className} activity-card`} width='100%'>
      <PaperBackgroundMolecule className='activity-card__paper'>
        <Stack
          className='activity-card__content'
          direction='column'
          spacing={2}>
          {renderHeader()}
          {renderHeading()}
          {renderDescription()}
          {renderSkills()}
          {renderNavLinks()}
        </Stack>
      </PaperBackgroundMolecule>
    </Box>
  );
};
export default ActivityCardMolecule;
