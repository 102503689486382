import React, { useMemo } from 'react';

import { Stack } from '@mui/material';

import useAssetsResources from '../../../../../../hooks/resources/useAssetsResources';
import useExternalNavigation from '../../../../../../hooks/navigation/useExternalNavigation';

import UrlNavEndpoint from '../../../../../../tools/navigation/endpoints/UrlNavEndpoint';
import UserSocial from '../../../../../../types/business/user/UserSocial';
import UserPreferences from '../../../../../../types/business/user/preferences/UserPreferences';

import { ComponentProps } from '../../../../ComponentProps';
import NavListMolecule, {
  NavListMoleculeItem,
} from '../../nav/NavListMolecule';
import ContactButtonMolecule from '../../../buttons/contact/ContactButtonMolecule';

import {
  COMMON_SOCIAL_LINK_TYPE,
  GITHUB_SOCIAL_LINK_TYPE,
  GITLAB_SOCIAL_LINK_TYPE,
  LINKEDIN_SOCIAL_LINK_TYPE,
} from '../../../../../../types/business/social/link/SocialLinkType';

type UserSocialListMoleculeProps = ComponentProps & {
  social?: UserSocial;
  preferences?: UserPreferences;
};

const UserSocialListMolecule: React.FC<UserSocialListMoleculeProps> = ({
  className,
  social,
  preferences,
}) => {
  const { gitLabLogo, gitHubLogo, linkedInLogo } = useAssetsResources();
  const { toLinkedInUser, toGitLab, toGitHub } = useExternalNavigation();

  const socialLinkNavs = useMemo((): NavListMoleculeItem[] => {
    const navLinks: NavListMoleculeItem[] = [];

    if (social && social.links) {
      social.links.forEach((link) => {
        if (link.type === COMMON_SOCIAL_LINK_TYPE) {
          navLinks.push({
            image: {
              alt: link.type,
            },
            to: { url: link.url } as UrlNavEndpoint,
          });
        }
        if (link.type === GITHUB_SOCIAL_LINK_TYPE) {
          navLinks.push({
            image: gitHubLogo,
            to: toGitHub(link.path),
          });
        }
        if (link.type === GITLAB_SOCIAL_LINK_TYPE) {
          navLinks.push({
            image: gitLabLogo,
            to: toGitLab(link.path),
          });
        }
        if (link.type === LINKEDIN_SOCIAL_LINK_TYPE) {
          navLinks.push({
            image: linkedInLogo,
            to: toLinkedInUser(link.path),
          });
        }
      });
    }

    return navLinks;
  }, [
    social,
    gitHubLogo,
    gitLabLogo,
    linkedInLogo,
    toGitHub,
    toGitLab,
    toLinkedInUser,
  ]);

  const renderContactButton = () =>
    social?.contacts &&
    social.contacts.length > 0 && (
      <ContactButtonMolecule
        className='user-social-list__contact'
        contacts={social.contacts}
        preference={preferences?.contacts}
      />
    );

  const renderSocialLinks = () =>
    social?.links &&
    social.links.length > 0 && (
      <NavListMolecule
        className='user-social-list__links'
        navs={socialLinkNavs}
        size='medium'
        badge
      />
    );

  return social ? (
    <Stack
      className={`${className} user-social-list`}
      direction='row'
      alignItems='start'
      justifyContent='space-between'
      useFlexGap
      flexWrap='wrap'
      spacing={2}>
      {renderContactButton()}
      {renderSocialLinks()}
    </Stack>
  ) : null;
};

export default UserSocialListMolecule;
