import loadResource, { LoadResource } from '../loadResource';

import I18nLanguage from '../../../../tools/internationalization/i18n/types/I18nLanguage';
import CvResource from '../../../../tools/resources/types/business/cv/CvResource';

import {
  LOAD_CV_RESOURCE_ERROR,
  LOAD_CV_RESOURCE_REQUEST,
  LOAD_CV_RESOURCE_RESPONSE,
} from '../resourceActionTypes';
import RESOURCES_API from '../../../../tools/api/resources';

export type LoadCvResource = LoadResource<
  typeof LOAD_CV_RESOURCE_REQUEST,
  typeof LOAD_CV_RESOURCE_RESPONSE,
  typeof LOAD_CV_RESOURCE_ERROR,
  CvResource
>;

const loadCvResource = (language: I18nLanguage) =>
  loadResource(
    LOAD_CV_RESOURCE_REQUEST,
    LOAD_CV_RESOURCE_RESPONSE,
    LOAD_CV_RESOURCE_ERROR,
    () => RESOURCES_API.getCv(language),
  );

export default loadCvResource;
