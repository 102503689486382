import loadResource, { LoadResource } from '../loadResource';

import I18nLanguage from '../../../../tools/internationalization/i18n/types/I18nLanguage';
import UserResource from '../../../../tools/resources/types/business/users/UserResource';

import {
  LOAD_USER_RESOURCE_ERROR,
  LOAD_USER_RESOURCE_REQUEST,
  LOAD_USER_RESOURCE_RESPONSE,
} from '../resourceActionTypes';
import RESOURCES_API from '../../../../tools/api/resources';

export type LoadUserResource = LoadResource<
  typeof LOAD_USER_RESOURCE_REQUEST,
  typeof LOAD_USER_RESOURCE_RESPONSE,
  typeof LOAD_USER_RESOURCE_ERROR,
  UserResource
>;

const loadUserResource = (language: I18nLanguage) =>
  loadResource(
    LOAD_USER_RESOURCE_REQUEST,
    LOAD_USER_RESOURCE_RESPONSE,
    LOAD_USER_RESOURCE_ERROR,
    () => RESOURCES_API.getUser(language),
  );

export default loadUserResource;
