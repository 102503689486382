export const LOAD_VERSION_RESOURCE_REQUEST = 'Resource/Request/LOAD_VERSION';
export const LOAD_VERSION_RESOURCE_RESPONSE = 'Resource/Response/LOAD_VERSION';
export const LOAD_VERSION_RESOURCE_ERROR = 'Resource/Error/LOAD_VERSION';

export const LOAD_LANGUAGE_BUNDLES_RESOURCE_REQUEST =
  'Resource/Request/LOAD_LANGUAGE_BUNDLES';
export const LOAD_LANGUAGE_BUNDLES_RESOURCE_RESPONSE =
  'Resource/Response/LOAD_LANGUAGE_BUNDLES';
export const LOAD_LANGUAGE_BUNDLES_RESOURCE_ERROR =
  'Resource/Error/LOAD_LANGUAGE_BUNDLES';

export const LOAD_CV_RESOURCE_REQUIREMENT = 'Resource/Requirement/LOAD_CV';
export const LOAD_CV_RESOURCE_REQUEST = 'Resource/Request/LOAD_CV';
export const LOAD_CV_RESOURCE_RESPONSE = 'Resource/Response/LOAD_CV';
export const LOAD_CV_RESOURCE_ERROR = 'Resource/Error/LOAD_CV';

export const LOAD_USER_RESOURCE_REQUIREMENT = 'Resource/Requirement/LOAD_USER';
export const LOAD_USER_RESOURCE_REQUEST = 'Resource/Request/LOAD_USER';
export const LOAD_USER_RESOURCE_RESPONSE = 'Resource/Response/LOAD_USER';
export const LOAD_USER_RESOURCE_ERROR = 'Resource/Error/LOAD_USER';

export const LOAD_ACTIVITIES_RESOURCE_REQUIREMENT =
  'Resource/Requirement/LOAD_ACTIVITIES';
export const LOAD_ACTIVITIES_RESOURCE_REQUEST =
  'Resource/Request/LOAD_ACTIVITIES';
export const LOAD_ACTIVITIES_RESOURCE_RESPONSE =
  'Resource/Response/LOAD_ACTIVITIES';
export const LOAD_ACTIVITIES_RESOURCE_ERROR = 'Resource/Error/LOAD_ACTIVITIES';

export const LOAD_SUBACTIVITIES_RESOURCE_REQUIREMENT =
  'Resource/Requirement/LOAD_SUBACTIVITIES';
export const LOAD_SUBACTIVITIES_RESOURCE_REQUEST =
  'Resource/Request/LOAD_SUBACTIVITIES';
export const LOAD_SUBACTIVITIES_RESOURCE_RESPONSE =
  'Resource/Response/LOAD_SUBACTIVITIES';
export const LOAD_SUBACTIVITIES_RESOURCE_ERROR =
  'Resource/Error/LOAD_SUBACTIVITIES';

export const LOAD_PROJECTS_RESOURCE_REQUIREMENT =
  'Resource/Requirement/LOAD_PROJECTS';
export const LOAD_PROJECTS_RESOURCE_REQUEST = 'Resource/Request/LOAD_PROJECTS';
export const LOAD_PROJECTS_RESOURCE_RESPONSE =
  'Resource/Response/LOAD_PROJECTS';
export const LOAD_PROJECTS_RESOURCE_ERROR = 'Resource/Error/LOAD_PROJECTS';

export const LOAD_PROVIDERS_RESOURCE_REQUIREMENT =
  'Resource/Requirement/LOAD_PROVIDERS';
export const LOAD_PROVIDERS_RESOURCE_REQUEST =
  'Resource/Request/LOAD_PROVIDERS';
export const LOAD_PROVIDERS_RESOURCE_RESPONSE =
  'Resource/Response/LOAD_PROVIDERS';
export const LOAD_PROVIDERS_RESOURCE_ERROR = 'Resource/Error/LOAD_PROVIDERS';

export const LOAD_POSITIONS_RESOURCE_REQUIREMENT =
  'Resource/Requirement/LOAD_POSITIONS';
export const LOAD_POSITIONS_RESOURCE_REQUEST =
  'Resource/Request/LOAD_POSITIONS';
export const LOAD_POSITIONS_RESOURCE_RESPONSE =
  'Resource/Response/LOAD_POSITIONS';
export const LOAD_POSITIONS_RESOURCE_ERROR = 'Resource/Error/LOAD_POSITIONS';

export const LOAD_SKILLS_RESOURCE_REQUIREMENT =
  'Resource/Requirement/LOAD_SKILLS';
export const LOAD_SKILLS_RESOURCE_REQUEST = 'Resource/Request/LOAD_SKILLS';
export const LOAD_SKILLS_RESOURCE_RESPONSE = 'Resource/Response/LOAD_SKILLS';
export const LOAD_SKILLS_RESOURCE_ERROR = 'Resource/Error/LOAD_SKILLS';
