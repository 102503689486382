import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { RootState } from '../../storage/reducers/rootReducer';
import useRootDispatch from '../../storage/dispatch/rootDispatch';
import {
  RequestedResourceStoreEntry,
  ResourcesStoreEntry,
} from '../../storage/reducers/resourcesReducer';
import { LoadResource } from '../../storage/actions/resources/loadResource';
import { default as loadVersionResourceAction } from '../../storage/actions/resources/load/loadVersionResource';
import { RequireResource } from '../../storage/actions/resources/requireResource';
import { default as requireCvResourceAction } from '../../storage/actions/resources/require/requireCvResource';
import { default as requireUserResourceAction } from '../../storage/actions/resources/require/requireUserResource';
import { default as requireActivitiesResourceAction } from '../../storage/actions/resources/require/requireActivitiesResource';
import { default as requireSubactivitiesResourceAction } from '../../storage/actions/resources/require/requireSubactivitiesResource';
import { default as requirePositionsResourceAction } from '../../storage/actions/resources/require/requirePositionsResource';
import { default as requireProvidersResourceAction } from '../../storage/actions/resources/require/requireProvidersResource';
import { default as requireProjectsResourceAction } from '../../storage/actions/resources/require/requireProjectsResource';
import { default as requireSkillsResourceAction } from '../../storage/actions/resources/require/requireSkillsResource';

import Resource from '../../tools/resources/types/Resource';
import VersionResource from '../../tools/resources/types/business/version/VersionResource';

interface UseStaticResourcesLoader {
  loadVersionResource(): void;

  loadCvResource(): void;

  loadUserResource(): void;

  loadActivitiesResource(): void;

  loadSubactivitiesResource(): void;

  loadProjectsResource(): void;

  loadProvidersResource(): void;

  loadPositionsResource(): void;

  loadSkillsResource(): void;
}

/**
 * @see useStaticResourcesLoaderEffects - Receiver of the useCvLoader events
 */
const useStaticResourcesLoader = (): UseStaticResourcesLoader => {
  const dispatch = useRootDispatch();

  const {
    version,
    cv,
    user,
    activities,
    subactivities,
    projects,
    providers,
    positions,
    skills,
  } = useSelector((state: RootState) => state.resourcesReducer);

  const requireResource = useCallback(
    <T extends Resource>(
      resource: RequestedResourceStoreEntry<T>,
      action: (dispatch: Dispatch<RequireResource<string>>) => void,
    ) => {
      if (!resource.required && !resource.loading && !resource.value) {
        dispatch(action);
      }
    },
    [dispatch],
  );

  const loadResource = useCallback(
    <T extends Resource>(
      resource: ResourcesStoreEntry<T>,
      action: (
        dispatch: Dispatch<LoadResource<string, string, string, T>>,
      ) => Promise<void>,
    ) => {
      if (!resource.value && !resource.loading && !resource.error) {
        dispatch(action);
      }
    },
    [dispatch],
  );

  const loadVersionResource = useCallback(() => {
    loadResource<VersionResource>(version, loadVersionResourceAction());
  }, [loadResource, version]);

  const loadCvResource = useCallback(() => {
    requireResource(cv, requireCvResourceAction());
  }, [requireResource, cv]);

  const loadUserResource = useCallback(() => {
    requireResource(user, requireUserResourceAction());
  }, [requireResource, user]);

  const loadActivitiesResource = useCallback(() => {
    requireResource(activities, requireActivitiesResourceAction());
  }, [requireResource, activities]);

  const loadSubactivitiesResource = useCallback(() => {
    requireResource(subactivities, requireSubactivitiesResourceAction());
  }, [requireResource, subactivities]);

  const loadProjectsResource = useCallback(() => {
    requireResource(projects, requireProjectsResourceAction());
  }, [requireResource, projects]);

  const loadProvidersResource = useCallback(() => {
    requireResource(providers, requireProvidersResourceAction());
  }, [requireResource, providers]);

  const loadPositionsResource = useCallback(() => {
    requireResource(positions, requirePositionsResourceAction());
  }, [requireResource, positions]);

  const loadSkillsResource = useCallback(() => {
    requireResource(skills, requireSkillsResourceAction());
  }, [requireResource, skills]);

  return {
    loadVersionResource,
    loadCvResource,
    loadUserResource,
    loadActivitiesResource,
    loadSubactivitiesResource,
    loadProjectsResource,
    loadProvidersResource,
    loadPositionsResource,
    loadSkillsResource,
  };
};

export default useStaticResourcesLoader;
