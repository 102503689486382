export const ACTIVITY_PERIOD_FILTER = 'ACTIVITY_PERIOD';
export const ACTIVITY_TYPE_FILTER = 'ACTIVITY_TYPE';
export const ACTIVITY_POSITION_FILTER = 'ACTIVITY_POSITION';

type FilterType =
  | typeof ACTIVITY_PERIOD_FILTER
  | typeof ACTIVITY_TYPE_FILTER
  | typeof ACTIVITY_POSITION_FILTER;

export default FilterType;
