import { Dispatch } from 'redux';

import CvActivity from '../../../types/business/cv/fields/activity/CvActivity';

import { SET_ACTUAL_CV_ACTIVITIES } from './cvActionTypes';

export type SetActualCvActivities = {
  type: typeof SET_ACTUAL_CV_ACTIVITIES;
  payload?: CvActivity[];
};

const setActualCvActivities =
  (cvActivities?: CvActivity[]) =>
  (dispatch: Dispatch<SetActualCvActivities>) => {
    dispatch({ type: SET_ACTUAL_CV_ACTIVITIES, payload: cvActivities });
  };

export default setActualCvActivities;
