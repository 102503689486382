import React from 'react';

import { West } from '@mui/icons-material';

import useInternationalization from '../../../../../../hooks/internationalization/useInternationalization';

import { ComponentProps } from '../../../../ComponentProps';
import ChipAtom from '../../../../atoms/chips/ChipAtom';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { CHIP_FILTER_PLACEHOLDER_TEXT_MAPPING } from '../../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';

type FilterPlaceholderChipMoleculeProps = ComponentProps;

export const FilterPlaceholderChipMolecule: React.FC<
  FilterPlaceholderChipMoleculeProps
> = ({ className }) => {
  const { t } = useInternationalization(MOLECULES_LOCALE_NAMESPACE);

  return (
    <ChipAtom
      className={`${className} filter-placeholder`}
      avatar={<West />}
      avatarSx={{ opacity: 0.5 }}
      label={t(CHIP_FILTER_PLACEHOLDER_TEXT_MAPPING)}
      labelSx={{ opacity: 0.5 }}
      variant='outlined'
      borderStyle='none'
      scale={1.4}
    />
  );
};

export default FilterPlaceholderChipMolecule;
