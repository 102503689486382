import { Dispatch } from 'redux';

import Constructed from '../../../tools/constructed/Constructed';
import CvActivitiesConstructFlow from '../../../types/business/cv/fields/activity/constructed/CvActivitiesConstructFlow';
import ConstructedCvActivity from '../../../types/business/cv/fields/activity/constructed/ConstructedCvActivity';

import { SET_INITIAL_CV_ACTIVITIES } from './cvActionTypes';

export type SetInitialCvActivities = {
  type: typeof SET_INITIAL_CV_ACTIVITIES;
  payload: Constructed<CvActivitiesConstructFlow, ConstructedCvActivity[]>;
};

const setInitialCvActivities =
  (
    constructedCvActivities: Constructed<
      CvActivitiesConstructFlow,
      ConstructedCvActivity[]
    >,
  ) =>
  (dispatch: Dispatch<SetInitialCvActivities>) => {
    dispatch({
      type: SET_INITIAL_CV_ACTIVITIES,
      payload: constructedCvActivities,
    });
  };

export default setInitialCvActivities;
