import React, { useEffect, useMemo } from 'react';

import {
  Accordion,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import useInternationalization from '../../../../../hooks/internationalization/useInternationalization';
import useCvFiltering from '../../../../../hooks/business/cv/useCvFiltering';

import FilterData from '../../../../../types/business/filter/data/FilterData';
import ActivityTypeFilterData from '../../../../../types/business/filter/data/variants/type/ActivityTypeFilterData';
import ActivityPositionFilterData from '../../../../../types/business/filter/data/variants/position/ActivityPositionFilterData';
import ActivityPeriodFilterData from '../../../../../types/business/filter/data/variants/period/ActivityPeriodFilterData';

import DialogMoleculeProps from '../DialogMoleculeProps';
import ButtonAtom from '../../../atoms/buttons/regular/ButtonAtom';
import TypographyAtom from '../../../atoms/typography/TypographyAtom';
import ActivityTypeFilterDialogMoleculeAccordion from './accordions/ActivityTypeFilterDialogMoleculeAccordion';
import ActivityPositionFilterDialogMoleculeAccordion from './accordions/ActivityPositionFilterDialogMoleculeAccordion';
import ActivityPeriodFilterDialogMoleculeAccordion from './accordions/ActivityPeriodFilterDialogMoleculeAccordion';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import {
  DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_TITLE_MAPPING,
  DIALOG_FILTER_ACCORDION_ACTIVITY_POSITION_TITLE_MAPPING,
  DIALOG_FILTER_ACCORDION_ACTIVITY_TYPE_TITLE_MAPPING,
  DIALOG_FILTER_APPLY_MAPPING,
  DIALOG_FILTER_CLOSE_MAPPING,
  DIALOG_FILTER_RESET_ALL_MAPPING,
  DIALOG_FILTER_TITLE_MAPPING,
} from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';
import {
  ACTIVITY_PERIOD_FILTER,
  ACTIVITY_POSITION_FILTER,
  ACTIVITY_TYPE_FILTER,
} from '../../../../../types/business/filter/data/prototype/fields/FilterType';

type FilterScheme = {
  activityType: ActivityTypeFilterData[];
  activityPosition: ActivityPositionFilterData[];
  activityPeriod: ActivityPeriodFilterData[];
};

type FilterDialogMoleculeProps = DialogMoleculeProps;

const FilterDialogMolecule: React.FC<FilterDialogMoleculeProps> = ({
  className,
  open,
  handleClose,
}) => {
  const { t } = useInternationalization(MOLECULES_LOCALE_NAMESPACE);
  const { availableFilterData, currentFilters, applyNewFilters } =
    useCvFiltering();

  const prepareFilterScheme = (filterData: FilterData[]) => {
    const newFilterScheme: FilterScheme = {
      activityType: [],
      activityPosition: [],
      activityPeriod: [],
    };

    filterData.forEach((data) => {
      if (data.type === ACTIVITY_TYPE_FILTER) {
        newFilterScheme.activityType.push(data);
      }
      if (data.type === ACTIVITY_POSITION_FILTER) {
        newFilterScheme.activityPosition.push(data);
      }
      if (data.type === ACTIVITY_PERIOD_FILTER) {
        newFilterScheme.activityPeriod.push(data);
      }
    });

    return newFilterScheme;
  };

  const [filterScheme, setFilterScheme] = React.useState<FilterScheme>(
    prepareFilterScheme(currentFilters),
  );

  const availableFilterDataScheme = useMemo(
    () => prepareFilterScheme(availableFilterData),
    [availableFilterData],
  );

  useEffect(() => {
    setFilterScheme(prepareFilterScheme(currentFilters));
  }, [currentFilters]);

  const applyFilters = () => {
    applyNewFilters([
      ...filterScheme.activityType,
      ...filterScheme.activityPosition,
      ...filterScheme.activityPeriod,
    ]);
    handleClose();
  };

  const cancelFilterChanges = () => {
    setFilterScheme(prepareFilterScheme(currentFilters));
    handleClose();
  };

  const resetFilters = () => {
    setFilterScheme({
      activityType: [],
      activityPosition: [],
      activityPeriod: [],
    });
  };

  const onChangeActivityTypeFilter = (filterData: ActivityTypeFilterData[]) => {
    setFilterScheme({
      ...filterScheme,
      activityType: filterData,
    });
  };

  const onChangeActivityPositionFilter = (
    filterData: ActivityPositionFilterData[],
  ) => {
    setFilterScheme({
      ...filterScheme,
      activityPosition: filterData,
    });
  };

  const onChangeActivityPeriodFilter = (
    filterData: ActivityPeriodFilterData[],
  ) => {
    setFilterScheme({
      ...filterScheme,
      activityPeriod: filterData,
    });
  };

  const renderAccordionSummary = (
    accordionId: string,
    accordionTitle: string,
  ) => (
    <AccordionSummary
      className={`${accordionId}__summary`}
      expandIcon={<ExpandMore />}
      aria-controls={`${accordionId}-content`}
      id={`${accordionId}-header`}>
      <TypographyAtom className={`${accordionId}__summary-title`}>
        {accordionTitle}
      </TypographyAtom>
    </AccordionSummary>
  );

  const renderActivityTypeAccordion = () => (
    <Accordion
      className='filter-dialog__activity-type-accordion'
      defaultExpanded
      square
      sx={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
      {renderAccordionSummary(
        'activity-type',
        t(DIALOG_FILTER_ACCORDION_ACTIVITY_TYPE_TITLE_MAPPING),
      )}
      <ActivityTypeFilterDialogMoleculeAccordion
        className='activity-type-accordion'
        initialFilterData={availableFilterDataScheme.activityType}
        currentFilterData={filterScheme.activityType}
        onChange={onChangeActivityTypeFilter}
      />
    </Accordion>
  );

  const renderActivityPositionAccordion = () => (
    <Accordion
      className='filter-dialog__activity-position-accordion'
      defaultExpanded
      square>
      {renderAccordionSummary(
        'activity-position',
        t(DIALOG_FILTER_ACCORDION_ACTIVITY_POSITION_TITLE_MAPPING),
      )}
      <ActivityPositionFilterDialogMoleculeAccordion
        className='activity-position-accordion'
        initialFilterData={availableFilterDataScheme.activityPosition}
        currentFilterData={filterScheme.activityPosition}
        onChange={onChangeActivityPositionFilter}
      />
    </Accordion>
  );

  const renderActivityPeriodAccordion = () => (
    <Accordion
      className='filter-dialog__activity-period-accordion'
      defaultExpanded
      square
      sx={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}>
      {renderAccordionSummary(
        'activity-period',
        t(DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_TITLE_MAPPING),
      )}
      <ActivityPeriodFilterDialogMoleculeAccordion
        className='activity-pediod-accordion'
        initialFilterData={availableFilterDataScheme.activityPeriod}
        currentFilterData={filterScheme.activityPeriod}
        onChange={onChangeActivityPeriodFilter}
      />
    </Accordion>
  );

  const renderDialogContent = () => (
    <>
      {renderActivityTypeAccordion()}
      {renderActivityPositionAccordion()}
      {renderActivityPeriodAccordion()}
    </>
  );

  return (
    <Dialog
      className={`${className} filter-dialog`}
      open={open}
      onClose={cancelFilterChanges}
      fullWidth
      maxWidth='sm'
      scroll='paper'
      PaperProps={{ sx: { borderRadius: 5 } }}>
      <DialogTitle>{t(DIALOG_FILTER_TITLE_MAPPING)}</DialogTitle>
      <DialogContent>{renderDialogContent()}</DialogContent>
      <DialogActions sx={{ paddingRight: 3 }}>
        <ButtonAtom
          type='reset'
          onClick={resetFilters}
          color='error'
          sx={{ borderRadius: 4 }}>
          {t(DIALOG_FILTER_RESET_ALL_MAPPING)}
        </ButtonAtom>
        <ButtonAtom onClick={cancelFilterChanges} sx={{ borderRadius: 4 }}>
          {t(DIALOG_FILTER_CLOSE_MAPPING)}
        </ButtonAtom>
        <ButtonAtom
          type='submit'
          onClick={applyFilters}
          color='success'
          sx={{ borderRadius: 4 }}>
          {t(DIALOG_FILTER_APPLY_MAPPING)}
        </ButtonAtom>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialogMolecule;
