import ConstructedCvActivity from '../ConstructedCvActivity';
import PositionsResource from '../../../../../../../tools/resources/types/business/positions/PositionsResource';
import CvActivityPositionId from '../fields/CvActivityPositionId';
import { createPositionFromResource } from '../../fields/position/CvActivityPosition';

const updateConstructedActivityWithPositionResource = (
  constructedActivity: ConstructedCvActivity,
  positionsResource: PositionsResource,
): ConstructedCvActivity => ({
  ...constructedActivity,
  position: createPositionFromResource(
    positionsResource.find(
      (position) =>
        position.id === (constructedActivity.position as CvActivityPositionId),
    ),
  ),
});

export default updateConstructedActivityWithPositionResource;
