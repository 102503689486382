import Date from '../../date/Date';
import Period from '../Period';

import isSinglePeriod from '../guards/isSinglePeriod';
import compareDates from '../../date/utils/compareDates';

const getEndOfPeriod = (period: Period): Date | undefined => {
  if (isSinglePeriod(period)) {
    return period.end;
  }
  if (period.length === 1) {
    return period[0].end;
  }
  let end: Date | undefined;
  period.forEach((p) => {
    if (compareDates(p.end, end) > 0) {
      ({ end } = p);
    }
  });
  return end;
};

export default getEndOfPeriod;
