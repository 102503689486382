import React, { useMemo } from 'react';

import { Box } from '@mui/material';

import useCurrentBreakpoint from '../../../../../hooks/theme/useCurrentBreakpoint';
import useCv from '../../../../../hooks/business/cv/useCv';

import calculateSkillsExperience, {
  Experience,
} from '../../../../../tools/business/cv/calculate/calculateSkillsExperience';
import sortSkillsExperience from '../../../../../tools/business/cv/sort/sortSkillsExperience';

import { ComponentProps } from '../../../ComponentProps';
import SkillListMolecule from '../../../molecules/lists/skill/SkillListMolecule';
import PaperBackgroundMolecule from '../../../molecules/background/paper/PaperBackgroundMolecule';
import LoadingBackgroundMolecule from '../../../molecules/background/loading/LoadingBackgroundMolecule';

type CvSkillsOrganismProps = ComponentProps;

const CvSkillsOrganism: React.FC<CvSkillsOrganismProps> = ({
  className = 'skills-organism',
}) => {
  const breakpoint = useCurrentBreakpoint();
  const { cvActivities } = useCv();

  const experience = useMemo(() => {
    if (cvActivities) {
      if (cvActivities.length === 0) {
        return [];
      }

      let isDataAvailable = false;
      for (const activity of cvActivities) {
        const subactivities = activity.subactivities ?? [];
        for (const subactivity of subactivities) {
          if (subactivity.skills) {
            isDataAvailable = true;
            break;
          }
        }
      }

      if (isDataAvailable) {
        return sortSkillsExperience(calculateSkillsExperience(cvActivities));
      }
    }
    return undefined;
  }, [cvActivities]);
  const getSkillsLength = () => {
    switch (breakpoint) {
      case 'xs':
        return 8;
      case 'sm':
        return 10;
      default:
        return 12;
    }
  };

  const renderSkills = (loadedExperience: Experience[]) =>
    loadedExperience.length > 0 && (
      <PaperBackgroundMolecule className='skills-organism__background'>
        <SkillListMolecule
          className='skills-organism__skills-list'
          skills={loadedExperience}
          size='medium'
          maxSkills={getSkillsLength()}
        />
      </PaperBackgroundMolecule>
    );

  const renderLoading = () => (
    <LoadingBackgroundMolecule
      className='user-cv__loading'
      width='100%'
      height='100px'
    />
  );

  return (
    <Box className={`${className} skills-organism__content`}>
      {experience ? renderSkills(experience) : renderLoading()}
    </Box>
  );
};
export default CvSkillsOrganism;
