import { useCallback } from 'react';

import useStaticResources from '../resources/useStaticResources';

import I18nLanguage from '../../tools/internationalization/i18n/types/I18nLanguage';
import getI18nLanguage from '../../tools/internationalization/i18n/utils/getI18nLanguage';
import { I18N_DEFAULT_LANGUAGE } from '../../tools/internationalization/i18n/i18n';

interface UseAppLanguage {
  getLanguageToBundleAddon: (languages?: I18nLanguage[]) => I18nLanguage;
}

const useAppLanguage = (): UseAppLanguage => {
  const { languageBundles } = useStaticResources();

  const getLanguageToBundleAddon = useCallback(
    (languages?: I18nLanguage[]): I18nLanguage => {
      const currentLanguage = getI18nLanguage();
      if (languageBundles) {
        if (languages && languages.includes(currentLanguage)) {
          return currentLanguage;
        }
        return languageBundles.mainBundle;
      }
      return I18N_DEFAULT_LANGUAGE;
    },
    [languageBundles],
  );

  return {
    getLanguageToBundleAddon,
  };
};

export default useAppLanguage;
