import { Dispatch } from 'redux';

import { SET_APP_THEME_MODE } from './appActionTypes';
import AppThemeMode from '../../../tools/theme/app/AppThemeMode';

export type SetAppThemeMode = {
  type: typeof SET_APP_THEME_MODE;
  payload: AppThemeMode;
};

export const setAppThemeMode =
  (mode: AppThemeMode) => (dispatch: Dispatch<SetAppThemeMode>) => {
    dispatch({ type: SET_APP_THEME_MODE, payload: mode });
  };
