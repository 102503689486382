import ImageCommonSource from '../ImageCommonSource';
import ImageCommonThemedSource from '../variants/ImageCommonThemedSource';

const isImageSourceThemed = (
  source: ImageCommonSource,
): source is ImageCommonThemedSource =>
  (source as ImageCommonThemedSource).light !== undefined &&
  (source as ImageCommonThemedSource).dark !== undefined;

export default isImageSourceThemed;
