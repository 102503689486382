import React from 'react';

import { Grid, Stack } from '@mui/material';
import CvUser from '../../../../../../types/business/cv/fields/user/CvUser';
import { PositionExperience } from '../../../../../../tools/business/cv/calculate/calculatePositionsExperience';

import { ComponentProps } from '../../../../ComponentProps';
import TypographyAtom from '../../../../atoms/typography/TypographyAtom';
import GeolocationLinkMolecule from '../../../links/geolocation/GeolocationLinkMolecule';
import ExperienceButtonMolecule from '../../../buttons/experience/ExperienceButtonMolecule';

type UserInformationGridMoleculeProps = ComponentProps & {
  user: CvUser;
  experience: PositionExperience[];
};

const UserInformationGridMolecule: React.FC<
  UserInformationGridMoleculeProps
> = ({ className, user, experience }) => {
  const renderNameAge = () => (
    <Grid item>
      <TypographyAtom
        className='user-information__name-age'
        component='h5'
        variant='h5'>
        {user.firstName
          .concat(user.middleName ? ` ${user.middleName}` : '')
          .concat(user.lastName ? ` ${user.lastName}` : '')
          .concat(user.age ? `, ${user.age}` : '')}
      </TypographyAtom>
    </Grid>
  );

  const renderPosition = () => {
    if (user.position) {
      return (
        <Grid item>
          <Stack
            className='user-information__position-experience'
            direction='row'
            alignItems='center'
            spacing={0.5}>
            <TypographyAtom
              className='user-information__position'
              component='h5'
              variant='h5'
              sx={{ paddingTop: '2px' }}>
              {user.position.name}
            </TypographyAtom>
            {experience.length > 0 && (
              <ExperienceButtonMolecule
                className='user-information__experience'
                experience={experience}
              />
            )}
          </Stack>
        </Grid>
      );
    }
  };

  const renderLocation = () => {
    if (user.location) {
      return (
        <Grid item>
          <GeolocationLinkMolecule
            className='user-information__location-link'
            location={user.location}
          />
        </Grid>
      );
    }
  };

  return (
    <Grid
      className={`${className} user-information`}
      item
      container
      direction='column'
      rowSpacing={1.25}>
      {renderNameAge()}
      {renderPosition()}
      {renderLocation()}
    </Grid>
  );
};

export default UserInformationGridMolecule;
