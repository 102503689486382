import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';
import useRootDispatch from '../../../storage/dispatch/rootDispatch';
import setActualCvActivities from '../../../storage/actions/cv/setActualCvActivities';

import useCvLoader from './useCvLoader';

import filterActivities from '../../../tools/business/cv/filter/filterActivities';

/**
 * @see useCvFiltering - Producer of the useCvFilteringEffects events
 */
const useCvFilteringEffects = () => {
  const dispatch = useRootDispatch();
  const { filterEntity } = useSelector((state: RootState) => state.cvReducer);

  const { isCvActivitiesLoaded, initialCvActivities } = useCvLoader();

  useEffect(() => {
    if (isCvActivitiesLoaded) {
      dispatch(
        setActualCvActivities(
          filterActivities(filterEntity.filters, initialCvActivities),
        ),
      );
    }
  }, [
    initialCvActivities,
    filterEntity.filters,
    isCvActivitiesLoaded,
    dispatch,
  ]);
};

export default useCvFilteringEffects;
