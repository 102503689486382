import React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import useInternationalization from '../../../../../hooks/internationalization/useInternationalization';
import useTimedate from '../../../../../hooks/timedate/useTimedate';

import { ComponentProps } from '../../../ComponentProps';
import { PositionExperience } from '../../../../../tools/business/cv/calculate/calculatePositionsExperience';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import {
  TABLE_EXPERIENCE_EXPERIENCE_MAPPING,
  TABLE_EXPERIENCE_POSITION_MAPPING,
} from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';
import { Y_MO_D_TWO_LARGEST_DURATION_FORMAT } from '../../../../../tools/internationalization/humanizeDuration/HumanizeDurationFormats';

type ExperienceTableMoleculeProps = ComponentProps & {
  positionExperiences: PositionExperience[];
};

const ExperienceTableMolecule: React.FC<ExperienceTableMoleculeProps> = ({
  className,
  positionExperiences,
}) => {
  const { t } = useInternationalization(MOLECULES_LOCALE_NAMESPACE);
  const { dui } = useTimedate();

  const renderExperienceTableRows = () =>
    positionExperiences.map((row) => (
      <TableRow
        className='experience-table__row'
        key={row.positionId}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell
          className='experience-row__position-name'
          component='th'
          scope='row'>
          {row.positionName}
        </TableCell>
        <TableCell
          className='experience-row__position-experience'
          align='right'>
          {dui(row.experience, Y_MO_D_TWO_LARGEST_DURATION_FORMAT)}
        </TableCell>
      </TableRow>
    ));

  return (
    <TableContainer
      className={`${className} experience-table__container`}
      component={Paper}>
      <Table className='experience-table'>
        <TableHead className='experience-table__head'>
          <TableRow>
            <TableCell>{t(TABLE_EXPERIENCE_POSITION_MAPPING)}</TableCell>
            <TableCell align='right'>
              {t(TABLE_EXPERIENCE_EXPERIENCE_MAPPING)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='experience-table__body'>
          {renderExperienceTableRows()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExperienceTableMolecule;
