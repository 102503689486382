import { Action, Dispatch } from 'redux';

export type RequireResource<R> = {
  type: R;
};

const requireResource =
  <R>(request: R) =>
  (dispatch: Dispatch<Action<R>>) => {
    dispatch({ type: request });
  };

export default requireResource;
