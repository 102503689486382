import React from 'react';

import { Box } from '@mui/material';

import NotFoundUniversalTemplate from '../../templates/notFound/NotFoundUniversalTemplate';

const NotFoundPage: React.FC = () => (
  <Box className='not-found' width='100%' height='100%'>
    <NotFoundUniversalTemplate className='not-found__universal-template' />
  </Box>
);

export default NotFoundPage;
