import AppThemeMode, { LIGHT_THEME_MODE } from '../AppThemeMode';
import AppPalette from './AppPalette';

export const createAppPalette = (
  mode: AppThemeMode,
): { palette: AppPalette } => ({
  palette: {
    mode,
    ...(mode === LIGHT_THEME_MODE
      ? {
          // Light theme
          secondary: {
            main: '#e24329',
          },
          background: {
            default: '#f5f5f5',
            paper: '#fff',
          },
          error: {
            main: '#d32f2f',
            light: '#ef5350',
            // Changed from #c62828
            dark: '#b62424',
            contrastText: '#fff',
            // Custom color requires all default colors above
            // Light theme, error.dark alpha 20%
            phantom: 'rgba(198,40,40,0.2)',
          },
        }
      : {
          // Dark theme
          secondary: {
            main: '#fc6d26',
          },
          background: {
            paper: '#1e1e1e',
          },
          error: {
            main: '#f44336',
            light: '#e57373',
            dark: '#d32f2f',
            contrastText: '#fff',
            // Custom color requires all default colors above
            // Dark theme, error.dark alpha 25%
            phantom: 'rgba(211,47,47,0.25)',
          },
        }),
  } as AppPalette,
});
