import React from 'react';

import { Box } from '@mui/material';

import { ComponentProps } from '../../../ComponentProps';
import ThemeButtonMolecule from '../../../molecules/buttons/theme/ThemeButtonMolecule';

type WideHeaderOrganismProps = ComponentProps & {
  height?: string;
};

const WideHeaderOrganism: React.FC<WideHeaderOrganismProps> = ({
  className = 'wide-header-organism',
  height = 'auto',
}) => (
  <Box
    className={`${className} wide-header`}
    component='header'
    width='100%'
    height={height}
    display='flex'
    flexDirection='row'
    alignItems='center'
    justifyContent='flex-end'
    paddingY={1}
    paddingX={1}>
    <ThemeButtonMolecule className='header__theme-button' />
  </Box>
);

export default WideHeaderOrganism;
