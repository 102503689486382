import SinglePeriod from '../SinglePeriod';

import compareDates from '../../date/utils/compareDates';

/**
 * Compare two periods.
 * @param a First single period;
 * @param b Second single period;
 * @returns Positive if a > b or b is undefined, negative if a < b or a is undefined, 0 if a = b.
 */

const compareEndsOfSinglePeriods = (
  a: SinglePeriod | undefined,
  b: SinglePeriod | undefined,
): number => {
  if (!b) {
    return 1;
  }
  if (!a) {
    return -1;
  }
  return compareDates(a.end, b.end);
};

export default compareEndsOfSinglePeriods;
