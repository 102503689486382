import Period from '../Period';
import SinglePeriod from '../SinglePeriod';
import MultiPeriod from '../MultiPeriod';
import Date from './../../date/Date';
import isSinglePeriod from '../guards/isSinglePeriod';
import isMultiPeriod from '../guards/isMultiPeriod';
import compareDates from '../../date/utils/compareDates';

type Moment = {
  id: number;
  date: Date;
};

const flatPeriods = (periods: Period): SinglePeriod[] => {
  const result: SinglePeriod[] = [];

  if (isSinglePeriod(periods)) {
    result.push(periods);
    return result;
  }

  periods.forEach((p) => {
    if (isSinglePeriod(p)) {
      result.push(p);
    }
    if (isMultiPeriod(p)) {
      result.push(...p);
    }
  });
  return result;
};

const constructMoments = (periods: SinglePeriod[]): Moment[] => {
  const moments: Moment[] = [];
  periods.forEach((p, i) => {
    moments.push({ id: i, date: p.start });
    moments.push({ id: i, date: p.end });
  });
  return moments.sort((a, b) => compareDates(a.date, b.date));
};

const intersectMoments = (moments: Moment[]): SinglePeriod[] => {
  let startMoment: Moment | null = null;
  const stack: Moment[] = [];
  const result: SinglePeriod[] = [];
  moments.forEach((moment: Moment) => {
    if (startMoment === null) {
      startMoment = moment;
    }

    const momentIndex = stack.findIndex(
      (stackMoment) => stackMoment.id === moment.id,
    );
    if (momentIndex < 0) {
      stack.push(moment);
    } else {
      stack.splice(momentIndex, 1);
    }

    if (stack.length === 0) {
      result.push({
        start: startMoment.date,
        end: moment.date,
      });
      startMoment = null;
    }
  });

  return result;
};

const intersectPeriods = (period: Period): Period => {
  const flattenPeriods: SinglePeriod[] = flatPeriods(period);

  const moments: Moment[] = constructMoments(flattenPeriods);

  const result: SinglePeriod[] = intersectMoments(moments);
  if (result.length === 1) {
    return result[0] as SinglePeriod;
  }
  return result as MultiPeriod;
};

export default intersectPeriods;
