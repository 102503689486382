import ConstructedCvActivity from '../ConstructedCvActivity';
import ProvidersResource from '../../../../../../../tools/resources/types/business/providers/ProvidersResource';
import CvActivityProviderId from '../fields/CvActivityProviderId';
import { createProviderFromResource } from '../../fields/provider/CvActivityProvider';

const updateConstructedActivityWithProviderResource = (
  constructedActivity: ConstructedCvActivity,
  providersResource: ProvidersResource,
): ConstructedCvActivity => ({
  ...constructedActivity,
  provider: createProviderFromResource(
    providersResource.find(
      (provider) =>
        provider.id === (constructedActivity.provider as CvActivityProviderId),
    ),
  ),
});

export default updateConstructedActivityWithProviderResource;
