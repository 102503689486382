import ResourceName from '../object/fields/ResourceName';
import ResourcePath from '../object/fields/ResourcePath';
import ResourceObject from '../object/ResourceObject';

const RESOURCE_PATH_DELIMITER = '/';

const buildResourcePath = (
  resource: ResourceObject,
  ...dynamicNames: ResourceName[]
): ResourcePath => {
  let resourceName = resource.name;
  if (resourceName === undefined) {
    resourceName = dynamicNames.pop();
  }

  if (resource.parent) {
    return {
      path: `${
        buildResourcePath(resource.parent, ...dynamicNames).path
      }${RESOURCE_PATH_DELIMITER}${resourceName}`,
    };
  } else {
    if (resourceName?.length !== 0) {
      return { path: `${RESOURCE_PATH_DELIMITER}${resourceName}` };
    }
    return { path: '' };
  }
};

export default buildResourcePath;
