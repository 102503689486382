import moment from 'moment';

import NumberDate from '../NumberDate';

const fromMomentToNumberDate = (date: moment.Moment): NumberDate => ({
  year: date.year(),
  month: date.month(),
  day: date.date(),
});

export default fromMomentToNumberDate;
