import ProviderId from '../../../../../provider/ProviderId';
import ProviderName from '../../../../../provider/ProviderName';
import ProviderAvatar from '../../../../../provider/ProviderAvatar';
import ProviderLink from '../../../../../provider/ProviderLink';
import ProviderResource from '../../../../../../../tools/resources/types/business/providers/ProviderResource';

type CvActivityProvider = {
  id: ProviderId;
  name: ProviderName;
  avatar?: ProviderAvatar;
  link?: ProviderLink;
};

export const createProviderFromResource = (
  providerResource?: ProviderResource,
): CvActivityProvider | undefined =>
  providerResource && {
    id: providerResource.id,
    name: providerResource.name,
    avatar: providerResource.avatar,
    link: providerResource.link,
  };

export default CvActivityProvider;
