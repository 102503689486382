import ConstructedCvUser from '../ConstructedCvUser';
import CvUserPositionId from '../fields/CvUserPositionId';
import PositionsResource from '../../../../../../../tools/resources/types/business/positions/PositionsResource';

const updateConstructedUserWithPositionResource = (
  constructedUser: ConstructedCvUser,
  positionsResource: PositionsResource,
): ConstructedCvUser => ({
  ...constructedUser,
  position: positionsResource.find(
    (position) =>
      position.id === (constructedUser.position as CvUserPositionId),
  ),
});

export default updateConstructedUserWithPositionResource;
