import React from 'react';

import { Badge } from '@mui/material';
import { BadgeProps } from '@mui/material/Badge/Badge';

type BadgeAtomProps = BadgeProps;

const BadgeAtom: React.FC<BadgeAtomProps> = ({
  className,
  overlap,
  anchorOrigin,
  badgeContent,
  children,
}) => (
  <Badge
    className={className}
    overlap={overlap}
    anchorOrigin={anchorOrigin}
    badgeContent={badgeContent}>
    {children}
  </Badge>
);

export default BadgeAtom;
