import React from 'react';

import { Stack } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';

import useExternalNavigation from '../../../../../hooks/navigation/useExternalNavigation';

import GeolocationPlace from '../../../../../tools/geolocation/GeolocationPlace';

import { ComponentProps } from '../../../ComponentProps';
import TypographyAtom from '../../../atoms/typography/TypographyAtom';
import LinkAtom from '../../../atoms/links/LinkAtom';

type GeolocationLinkMoleculeProps = ComponentProps & {
  location: GeolocationPlace;
};

const GeolocationLinkMolecule: React.FC<GeolocationLinkMoleculeProps> = ({
  className,
  location,
}) => {
  const { toGoogleMapsSearch } = useExternalNavigation();
  const renderLink = () => (
    <Stack
      className={`${className} geolocation-link-content`}
      direction='row'
      spacing={0.3}>
      <PlaceIcon
        className='geolocation-link-content__icon'
        sx={{
          paddingTop: '6px',
          width: '20px',
          height: '25px',
        }}
      />
      <TypographyAtom
        className='geolocation-link-content__name'
        component='h6'
        variant='h6'>
        {location.name}
      </TypographyAtom>
    </Stack>
  );

  return location.mapping ? (
    <LinkAtom
      className={`${className} geolocation-link`}
      display='block'
      width='fit-content'
      to={toGoogleMapsSearch(location.mapping)}
      underline='hover'
      color='text.secondary'>
      {renderLink()}
    </LinkAtom>
  ) : (
    renderLink()
  );
};

export default GeolocationLinkMolecule;
