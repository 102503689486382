import Url from '../../web/url/Url';
import NavEndpoint from '../endpoints/NavEndpoint';
import InternalNavEndpoint from '../endpoints/InternalNavEndpoint';
import isExternalNavEndpoint from '../guards/isExternalNavEndpoint';
import isUrlNavEndpoint from '../guards/isUrlNavEndpoint';

const buildUrlFromEndpoint = (endpoint: NavEndpoint): Url => {
  if (isExternalNavEndpoint(endpoint)) {
    return `${endpoint.protocol}${endpoint.domain}${endpoint.path ?? ''}${
      endpoint.query ?? ''
    }`;
  }
  if (isUrlNavEndpoint(endpoint)) {
    return endpoint.url;
  }
  return (endpoint as InternalNavEndpoint).path;
};

export default buildUrlFromEndpoint;
