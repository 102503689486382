import React from 'react';

import useAppTheme from '../../../../../hooks/theme/useAppTheme';
import useInternationalization from '../../../../../hooks/internationalization/useInternationalization';
import useTimedate from '../../../../../hooks/timedate/useTimedate';
import useCvFiltering from '../../../../../hooks/business/cv/useCvFiltering';

import Filter from '../../../../../types/business/filter/common/Filter';
import ActivityType from '../../../../../types/business/activity/ActivityType';
import ActivityPeriodFilterValue from '../../../../../types/business/filter/data/variants/period/value/ActivityPeriodFilterValue';
import Position from '../../../../../types/business/position/Position';

import mapActivityTypeToLocaleMapping from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/mappings/mapActivityTypeToLocaleMapping';
import isCustomActivityPeriodFilterValue from '../../../../../types/business/filter/data/variants/period/value/guards/isCustomActivityPeriodFilterValue';
import isPredefinedActivityPeriodFilterValue from '../../../../../types/business/filter/data/variants/period/value/guards/isPredefinedActivityPeriodFilterValue';

import { ComponentProps } from '../../../ComponentProps';
import ChipAtom from '../../../atoms/chips/ChipAtom';

import {
  ACTIVITY_PERIOD_FILTER,
  ACTIVITY_POSITION_FILTER,
  ACTIVITY_TYPE_FILTER,
} from '../../../../../types/business/filter/data/prototype/fields/FilterType';
import {
  ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_FIVE_YEARS_KEY,
  ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_YEAR_KEY,
} from '../../../../../types/business/filter/data/variants/period/value/variants/predefined/PredefinedActivityPeriodFilterValueKey';
import { ll_LOCALE_DATE_FORMAT } from '../../../../../tools/timedate/date/formats/LocaleDateFormat';
import { LOWERCASE_PRESENT_DATE_FORMAT } from '../../../../../tools/timedate/date/formats/PresentDateFormat';
import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import {
  CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_CUSTOM_FROM_MAPPING,
  CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_CUSTOM_TO_MAPPING,
  CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_CUSTOM_UNTIL_MAPPING,
  CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_LAST_FIVE_YEARS_MAPPING,
  CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_LAST_YEAR_MAPPING,
  CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_MAPPING,
  CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_UNKNOWN_MAPPING,
  CHIP_FILTER_PREFIX_ACTIVITY_POSITION_MAPPING,
  CHIP_FILTER_PREFIX_ACTIVITY_TYPE_MAPPING,
} from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';

type FilterChipMoleculeProps = ComponentProps & {
  filter: Filter;
};

const FilterChipMolecule: React.FC<FilterChipMoleculeProps> = ({
  className,
  filter,
}) => {
  const { withTheme } = useAppTheme();
  const { t } = useInternationalization(MOLECULES_LOCALE_NAMESPACE);
  const { dai } = useTimedate();
  const { removeFilter } = useCvFiltering();

  const renderContentWithPrefix = (prefix: string, content: string) =>
    `${prefix}: ${content}`;

  const renderActivityTypeContent = () =>
    renderContentWithPrefix(
      t(CHIP_FILTER_PREFIX_ACTIVITY_TYPE_MAPPING),
      t(mapActivityTypeToLocaleMapping(filter.data as ActivityType)),
    );

  const renderActivityPositionContent = () =>
    renderContentWithPrefix(
      t(CHIP_FILTER_PREFIX_ACTIVITY_POSITION_MAPPING),
      (filter.data as Position).name,
    );

  const renderActivityPeriodText = () => {
    const filterValue = filter.data as ActivityPeriodFilterValue;

    if (isPredefinedActivityPeriodFilterValue(filterValue)) {
      if (filterValue.key === ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_YEAR_KEY) {
        return t(CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_LAST_YEAR_MAPPING);
      }
      if (
        filterValue.key === ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_FIVE_YEARS_KEY
      ) {
        return t(CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_LAST_FIVE_YEARS_MAPPING);
      }
    }

    if (isCustomActivityPeriodFilterValue(filterValue)) {
      let resultString = '';
      if (filterValue.value.start) {
        resultString = resultString.concat(
          `${t(CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_CUSTOM_FROM_MAPPING)} ${dai(
            filterValue.value.start,
            ll_LOCALE_DATE_FORMAT,
          )} `,
        );
      }

      if (filterValue.value.end) {
        if (filterValue.value.start) {
          resultString = resultString.concat(
            `${t(CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_CUSTOM_TO_MAPPING)} `,
          );
        } else {
          resultString = resultString.concat(
            `${t(CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_CUSTOM_UNTIL_MAPPING)} `,
          );
        }

        resultString = resultString.concat(
          `${dai(
            filterValue.value.end,
            ll_LOCALE_DATE_FORMAT,
            LOWERCASE_PRESENT_DATE_FORMAT,
          )}`,
        );
      }

      return resultString;
    }

    return t(CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_UNKNOWN_MAPPING);
  };

  const renderActivityPeriodContent = () =>
    renderContentWithPrefix(
      t(CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_MAPPING),
      renderActivityPeriodText(),
    );

  const renderDefaultContent = () => filter.id.toString();

  const renderLabel = (): string => {
    switch (filter.type) {
      case ACTIVITY_TYPE_FILTER: {
        return renderActivityTypeContent();
      }
      case ACTIVITY_POSITION_FILTER: {
        return renderActivityPositionContent();
      }
      case ACTIVITY_PERIOD_FILTER: {
        return renderActivityPeriodContent();
      }
      default: {
        return renderDefaultContent();
      }
    }
  };

  const handleDelete = () => {
    removeFilter(filter.id);
  };

  return (
    <ChipAtom
      className={`${className} filter-chip`}
      id={filter.id.toString()}
      label={renderLabel()}
      variant='outlined'
      scale={1.4}
      deleteIconScale={1.2}
      hoverBackgroundColor='error.phantom'
      borderColor={withTheme('error.main', 'error.dark')}
      borderColorHover='error.phantom'
      deleteIconColor={withTheme('error.main', 'error.dark')}
      deleteIconColorHover={withTheme('error.dark', 'error.main')}
      onClick={handleDelete}
      onDelete={handleDelete}
    />
  );
};

export default FilterChipMolecule;
