import Date from '../Date';
import fromDateToMoment from '../converters/fromDateToMoment';
import fromMomentToNumberDate from '../converters/fromMomentToNumberDate';

/**
 * Compare two dates.
 * @param a First date;
 * @param b Second date;
 * @returns Positive if a > b or b is undefined, negative if a < b or a is undefined, 0 if a = b or both are undefined.
 */
const compareDates = (a: Date | undefined, b: Date | undefined): number => {
  if (!b && !a) {
    return 0;
  }
  if (!b) {
    return 1;
  }
  if (!a) {
    return -1;
  }

  const aNumberDate = fromMomentToNumberDate(fromDateToMoment(a));
  const bNumberDate = fromMomentToNumberDate(fromDateToMoment(b));

  const compareYear = aNumberDate.year - bNumberDate.year;
  if (compareYear !== 0) {
    return compareYear;
  }
  const compareMonth = aNumberDate.month - bNumberDate.month;
  if (compareMonth !== 0) {
    return compareMonth;
  }
  const compareDay = aNumberDate.day - bNumberDate.day;
  if (compareDay !== 0) {
    return compareDay;
  }

  return 0;
};

export default compareDates;
