import React from 'react';

import { Box } from '@mui/material';
import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material';

import useInternationalization from '../../../../../hooks/internationalization/useInternationalization';
import useAppTheme from '../../../../../hooks/theme/useAppTheme';

import { ComponentProps } from '../../../ComponentProps';
import IconButtonAtom from '../../../atoms/buttons/icon/IconButtonAtom';
import ThemedAtom from '../../../atoms/themes/ThemedAtom';
import TooltipAtom from '../../../atoms/tooltips/TooltipAtom';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { BUTTON_THEME_TOOLTIP_MAPPING } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';

type ThemeButtonMoleculeProps = ComponentProps;

const ThemeButtonMolecule: React.FC<ThemeButtonMoleculeProps> = ({
  className,
}) => {
  const { t } = useInternationalization(MOLECULES_LOCALE_NAMESPACE);
  const { toggleTheme } = useAppTheme();

  return (
    <TooltipAtom
      className='theme-button__tooltip'
      title={t(BUTTON_THEME_TOOLTIP_MAPPING)}
      arrow>
      <Box className='theme-button__tooltip-wrapper'>
        <IconButtonAtom
          className={`${className} theme-button`}
          color='primary'
          onClick={toggleTheme}>
          <ThemedAtom
            childrenLight={<DarkModeOutlined />}
            childrenDark={<LightModeOutlined />}
          />
        </IconButtonAtom>
      </Box>
    </TooltipAtom>
  );
};

export default ThemeButtonMolecule;
