import CvActivity from '../../../../types/business/cv/fields/activity/CvActivity';
import Filter from '../../../../types/business/filter/common/Filter';
import ActivityPeriodFilter from '../../../../types/business/filter/common/variants/ActivityPeriodFilter';

import doPeriodsIntersect from '../../../timedate/period/utils/doPeriodsIntersect';
import fromDurationToSinglePeriod from '../../../timedate/period/converters/fromDurationToSinglePeriod';
import isPredefinedActivityPeriodFilterValue from '../../../../types/business/filter/data/variants/period/value/guards/isPredefinedActivityPeriodFilterValue';
import isCustomActivityPeriodFilterValue from '../../../../types/business/filter/data/variants/period/value/guards/isCustomActivityPeriodFilterValue';

import {
  ACTIVITY_PERIOD_FILTER,
  ACTIVITY_POSITION_FILTER,
  ACTIVITY_TYPE_FILTER,
} from '../../../../types/business/filter/data/prototype/fields/FilterType';

const applyPeriodFilterToActivity = (
  filter: ActivityPeriodFilter,
  activity: CvActivity,
) => {
  if (isPredefinedActivityPeriodFilterValue(filter.data)) {
    const filterPeriod = fromDurationToSinglePeriod(filter.data.value);
    return doPeriodsIntersect(activity.period, filterPeriod);
  }

  if (isCustomActivityPeriodFilterValue(filter.data)) {
    return doPeriodsIntersect(activity.period, filter.data.value);
  }

  return false;
};

const applyFilterToActivity = (filter: Filter, activity: CvActivity) => {
  switch (filter.type) {
    case ACTIVITY_TYPE_FILTER:
      return activity.type !== filter.data;
    case ACTIVITY_POSITION_FILTER:
      return activity.position?.id !== filter.data.id;
    case ACTIVITY_PERIOD_FILTER:
      return applyPeriodFilterToActivity(filter, activity);
    default:
      return true;
  }
};

const filterActivities = (filters: Filter[], activities: CvActivity[]) => {
  let filteredActivities = activities;

  filters.forEach((filter) => {
    filteredActivities = filteredActivities.filter((activity) =>
      applyFilterToActivity(filter, activity),
    );
  });

  return filteredActivities;
};

export default filterActivities;
