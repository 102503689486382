import FetchRequest from './request/FetchRequest';
import { ACCEPT_JSON } from './request/FetchHeaders';
import { FETCH_METHOD_GET } from './request/FetchMethod';

const checkStatus = (response: Response) => {
  if (200 <= response.status && response.status < 300) {
    return response;
  }
  throw new Error();
};

const formData = <T>(request: FetchRequest, response: Response): T => {
  const acceptHeader = request.headers.Accept;

  if (acceptHeader === ACCEPT_JSON) {
    return response.json() as T;
  }

  return response.text() as T;
};

const processRequest = <T>(
  request: FetchRequest,
  promise: Promise<Response>,
): Promise<T> =>
  promise
    .then((response) => checkStatus(response))
    .then((response) => formData<T>(request, response))
    .catch((e) => {
      throw e;
    });

const get = <T>(request: FetchRequest): Promise<T> =>
  processRequest<T>(
    request,
    fetch(request.url, { method: FETCH_METHOD_GET, headers: request.headers }),
  );

const fetcher = {
  get,
};

export default fetcher;
