export const PERSONAL_ACTIVITY_TYPE = 'personal';
export const COMMERCIAL_ACTIVITY_TYPE = 'commercial';
export const STARTUP_ACTIVITY_TYPE = 'startup';
export const INTERNSHIP_ACTIVITY_TYPE = 'internship';
export const EDUCATION_ACTIVITY_TYPE = 'education';

type ActivityType =
  | typeof PERSONAL_ACTIVITY_TYPE
  | typeof COMMERCIAL_ACTIVITY_TYPE
  | typeof STARTUP_ACTIVITY_TYPE
  | typeof INTERNSHIP_ACTIVITY_TYPE
  | typeof EDUCATION_ACTIVITY_TYPE;

export default ActivityType;
