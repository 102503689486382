import PositionResource from '../../../../../../../tools/resources/types/business/positions/PositionResource';
import Position from '../../../../../position/Position';

type CvActivityPosition = Position;

export const createPositionFromResource = (
  positionResource?: PositionResource,
): CvActivityPosition | undefined =>
  positionResource && {
    id: positionResource.id,
    name: positionResource.name,
  };

export default CvActivityPosition;
