import React from 'react';

import useAppTheme from '../../../../hooks/theme/useAppTheme';

import { LIGHT_THEME_MODE } from '../../../../tools/theme/app/AppThemeMode';

type ThemedAtomProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  childrenLight: React.ReactElement<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  childrenDark: React.ReactElement<any, any>;
};

const ThemedAtom: React.FC<ThemedAtomProps> = ({
  childrenLight,
  childrenDark,
}) => {
  const { themeMode } = useAppTheme();

  return themeMode === LIGHT_THEME_MODE ? childrenLight : childrenDark;
};

export default ThemedAtom;
