import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { createTheme } from '@mui/material';

import { RootState } from '../../storage/reducers/rootReducer';
import useRootDispatch from '../../storage/dispatch/rootDispatch';
import { setAppThemeMode } from '../../storage/actions/app/setAppThemeMode';

import AppTheme from '../../tools/theme/app/AppTheme';
import AppThemeMode, {
  DARK_THEME_MODE,
  LIGHT_THEME_MODE,
} from '../../tools/theme/app/AppThemeMode';
import { createAppPalette } from '../../tools/theme/app/palette/createAppPalette';

interface UseAppTheme {
  applySystemTheme: () => void;
  toggleTheme: () => void;
  appTheme: AppTheme;
  themeMode: AppThemeMode;

  withTheme<T>(light: T, dark: T): T;
}

const useAppTheme = (): UseAppTheme => {
  const dispatch = useRootDispatch();
  const { themeMode } = useSelector((state: RootState) => state.appReducer);

  const applySystemTheme = useCallback(() => {
    let obtainedMode: AppThemeMode = LIGHT_THEME_MODE;

    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      obtainedMode = DARK_THEME_MODE;
    }

    dispatch(setAppThemeMode(obtainedMode));
  }, [dispatch]);

  const toggleTheme = useCallback(() => {
    dispatch(
      setAppThemeMode(
        themeMode === LIGHT_THEME_MODE ? DARK_THEME_MODE : LIGHT_THEME_MODE,
      ),
    );
  }, [dispatch, themeMode]);

  const appTheme = useMemo(
    () => createTheme(createAppPalette(themeMode)),
    [themeMode],
  );

  const withTheme = <T>(light: T, dark: T): T => {
    if (themeMode === LIGHT_THEME_MODE) {
      return light;
    } else {
      return dark;
    }
  };

  return {
    applySystemTheme,
    toggleTheme,
    appTheme,
    themeMode,
    withTheme,
  };
};

export default useAppTheme;
