import requireResource, { RequireResource } from '../requireResource';
import { LOAD_CV_RESOURCE_REQUIREMENT } from '../resourceActionTypes';

export type RequireCvResource = RequireResource<
  typeof LOAD_CV_RESOURCE_REQUIREMENT
>;

const requireCvResource = () => requireResource(LOAD_CV_RESOURCE_REQUIREMENT);

export default requireCvResource;
