import React from 'react';

import { Box, Fade, Popper } from '@mui/material';
import { CancelOutlined, InfoOutlined } from '@mui/icons-material';

import {
  bindPopper,
  bindToggle,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import useInternationalization from '../../../../../hooks/internationalization/useInternationalization';

import { PositionExperience } from '../../../../../tools/business/cv/calculate/calculatePositionsExperience';

import { ComponentProps } from '../../../ComponentProps';
import IconButtonAtom from '../../../atoms/buttons/icon/IconButtonAtom';
import TooltipAtom from '../../../atoms/tooltips/TooltipAtom';
import ExperienceTableMolecule from '../../tables/experience/ExperienceTableMolecule';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { BUTTON_EXPERIENCE_TOOLTIP_MAPPING } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';

type ExperienceButtonMoleculeProps = ComponentProps & {
  experience: PositionExperience[];
};

const ExperienceButtonMolecule: React.FC<ExperienceButtonMoleculeProps> = ({
  className,
  experience,
}) => {
  const experiencePopperState = usePopupState({
    variant: 'popper',
    popupId: 'experience',
  });
  const { t } = useInternationalization(MOLECULES_LOCALE_NAMESPACE);

  return (
    <Box className={`${className} experience-button`}>
      <TooltipAtom
        className='experience-button__tooltip'
        title={
          experiencePopperState.isOpen
            ? null
            : t(BUTTON_EXPERIENCE_TOOLTIP_MAPPING)
        }
        arrow>
        <Box className='experience-button__tooltip-wrapper'>
          <IconButtonAtom
            className='experience-button__button'
            color='primary'
            {...bindToggle(experiencePopperState)}>
            {experiencePopperState.isOpen ? (
              <CancelOutlined />
            ) : (
              <InfoOutlined />
            )}
          </IconButtonAtom>
        </Box>
      </TooltipAtom>
      <Popper
        className='experience-button__popper'
        {...bindPopper(experiencePopperState)}
        transition
        placement='bottom-start'
        sx={{ zIndex: 1 }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={250}>
            <Box className='experience-button__popper-table-wrapper'>
              <ExperienceTableMolecule
                className='experience-button__popper-table'
                positionExperiences={experience}
              />
            </Box>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default ExperienceButtonMolecule;
