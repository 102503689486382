import requireResource, { RequireResource } from '../requireResource';
import { LOAD_ACTIVITIES_RESOURCE_REQUIREMENT } from '../resourceActionTypes';

export type RequireActivitiesResource = RequireResource<
  typeof LOAD_ACTIVITIES_RESOURCE_REQUIREMENT
>;

const requireActivitiesResource = () =>
  requireResource(LOAD_ACTIVITIES_RESOURCE_REQUIREMENT);

export default requireActivitiesResource;
