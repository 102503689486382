import React from 'react';

import { Paper } from '@mui/material';

import { ComponentProps } from '../../../ComponentProps';

type BackgroundPaperMoleculeProps = ComponentProps & {
  component?: React.ElementType;
  square?: boolean;
  sx?: object;
  children?: React.ReactNode;
};

const PaperBackgroundMolecule: React.FC<BackgroundPaperMoleculeProps> = ({
  className,
  component = 'div',
  square = false,
  sx,
  children,
}) => (
  <Paper
    className={`${className} paper-background`}
    component={component}
    sx={{ padding: '24px', borderRadius: square ? 0 : 3, ...sx }}
    elevation={0}>
    {children}
  </Paper>
);

export default PaperBackgroundMolecule;
