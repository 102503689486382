import CvActivity from '../../CvActivity';
import ConstructedCvActivity from '../ConstructedCvActivity';
import toCvActivity from './toCvActivity';

const toCvActivities = (
  constructedCvActivities?: ConstructedCvActivity[],
): CvActivity[] | undefined => {
  if (constructedCvActivities?.length === 0) {
    return [];
  }

  const cvActivities: CvActivity[] = [];
  constructedCvActivities?.forEach((activity) => {
    cvActivities.push(toCvActivity(activity));
  });

  if (cvActivities.length === 0) {
    return undefined;
  }
  return cvActivities;
};

export default toCvActivities;
