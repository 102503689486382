import moment from 'moment';

import Duration from '../Duration';

const fromDurationToMomentDuration = (duration: Duration): moment.Duration =>
  moment.duration({
    years: duration.years,
    months: duration.months,
    days: duration.days,
  });

export default fromDurationToMomentDuration;
