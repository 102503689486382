import ActivityPeriodFilterValuePrototype from '../../prototype/ActivityPeriodFilterValuePrototype';
import PredefinedActivityPeriodFilterValueParameter from './PredefinedActivityPeriodFilterValueParameter';
import PredefinedActivityPeriodFilterValueKey, {
  ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_FIVE_YEARS_KEY,
  ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_YEAR_KEY,
} from './PredefinedActivityPeriodFilterValueKey';

type PredefinedActivityPeriodFilterInnerValues = {
  [ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_YEAR_KEY]: PredefinedActivityPeriodFilterValue;
  [ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_FIVE_YEARS_KEY]: PredefinedActivityPeriodFilterValue;
};

export const PREDEFINED_ACTIVITY_PERIOD_FILTER_INNER_VALUES: PredefinedActivityPeriodFilterInnerValues =
  {
    [ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_YEAR_KEY]: {
      key: ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_YEAR_KEY,
      value: { years: 1, months: 0, days: 0 },
    },
    [ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_FIVE_YEARS_KEY]: {
      key: ACTIVITY_PERIOD_VALUE_PREDEFINED_LAST_FIVE_YEARS_KEY,
      value: { years: 5, months: 0, days: 0 },
    },
  };

type PredefinedActivityPeriodFilterValue = ActivityPeriodFilterValuePrototype<
  PredefinedActivityPeriodFilterValueKey,
  PredefinedActivityPeriodFilterValueParameter
>;

export default PredefinedActivityPeriodFilterValue;
