import React from 'react';

import { Box } from '@mui/material';

import { TemplateProps } from '../TemplateProps';
import NotFoundOrganism from '../../components/organisms/notFound/NotFoundOrganism';
import FooterOrganism from '../../components/organisms/footer/regular/FooterOrganism';
import WideHeaderOrganism from '../../components/organisms/header/wide/WideHeaderOrganism';

const NotFoundUniversalTemplate: React.FC<TemplateProps> = ({
  className = 'not-found-universal-template',
}) => (
  <Box
    className={`${className} not-found-template`}
    width='100%'
    height='100%'
    display='flex'
    flexDirection='column'>
    <WideHeaderOrganism className='not-found-template__header' height='54px' />
    <Box
      className='not-found-template__content-wrapper'
      width='100%'
      height='100%'
      alignSelf='stretch'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'>
      <NotFoundOrganism className='not-found-template__content' />
    </Box>
    <FooterOrganism className='not-found-template__footer' height='150px' />
  </Box>
);

export default NotFoundUniversalTemplate;
