export const ACCEPT_TEXT = 'text/plain';
export const ACCEPT_JSON = 'application/json';

export type FetchHeaderAccept = typeof ACCEPT_TEXT | typeof ACCEPT_JSON;

type FetchHeaders = {
  Accept: FetchHeaderAccept;
};

export default FetchHeaders;
