import React from 'react';

import { Box, Stack } from '@mui/material';

import useCvFiltering from '../../../../../hooks/business/cv/useCvFiltering';

import { ComponentProps } from '../../../ComponentProps';
import FilterChipMolecule from '../../chips/filter/FilterChipMolecule';
import FilterButtonMolecule from '../../buttons/filter/FilterButtonMolecule';
import FilterPlaceholderChipMolecule from '../../chips/filter/placeholder/FilterPlaceholderChipMolecule';

type FilterListMoleculeProps = ComponentProps;

const FilterListMolecule: React.FC<FilterListMoleculeProps> = ({
  className,
}) => {
  const { currentFilters, availableFilterData } = useCvFiltering();

  const renderFilterChips = () =>
    currentFilters.map((filter) => (
      <Box
        className='filter-list__item'
        component='li'
        key={filter.id}
        sx={{ listStyle: 'none' }}>
        <FilterChipMolecule
          className='filter-list__item-chip'
          filter={filter}
        />
      </Box>
    ));

  const renderChips = () =>
    currentFilters.length > 0 ? (
      renderFilterChips()
    ) : (
      <FilterPlaceholderChipMolecule className='filter-list__placeholder-chip' />
    );

  return availableFilterData.length === 0 ? null : (
    <Stack
      className={`${className} filter-list`}
      width='100%'
      component='ul'
      padding={0}
      margin={0}
      spacing={1.5}
      direction='row'
      useFlexGap
      flexWrap='wrap'>
      <FilterButtonMolecule className='filter-list__filter-button' />
      {renderChips()}
    </Stack>
  );
};

export default FilterListMolecule;
