import SkillId from '../../../../../../../skill/SkillId';
import SkillName from '../../../../../../../skill/SkillName';
import SkillAvatar from '../../../../../../../skill/SkillAvatar';
import SkillResource from '../../../../../../../../../tools/resources/types/business/skills/SkillResource';

type CvSubactivitySkill = {
  id: SkillId;
  name: SkillName;
  avatar?: SkillAvatar;
};

export const createSkillFromResource = (
  skillResource: SkillResource,
): CvSubactivitySkill => ({
  id: skillResource.id,
  name: skillResource.name,
  avatar: skillResource.avatar,
});

export default CvSubactivitySkill;
