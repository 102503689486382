import React from 'react';

import { Stack } from '@mui/material';

import useInternationalization from '../../../../../hooks/internationalization/useInternationalization';
import useAssetsResources from '../../../../../hooks/resources/useAssetsResources';
import useInternalNavigation from '../../../../../hooks/navigation/useInternalNavigation';

import { ComponentProps } from '../../../ComponentProps';
import ThemeButtonMolecule from '../../../molecules/buttons/theme/ThemeButtonMolecule';
import PaperBackgroundMolecule from '../../../molecules/background/paper/PaperBackgroundMolecule';
import AvatarAtom from '../../../atoms/avatars/AvatarAtom';
import TypographyAtom from '../../../atoms/typography/TypographyAtom';
import LinkAtom from '../../../atoms/links/LinkAtom';

import { APP_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/app/AppLocaleNamespace';
import { NAME_MAPPING } from '../../../../../tools/internationalization/i18n/localization/namespaces/app/AppLocaleNamespaceMappings';

type CompactHeaderOrganismProps = ComponentProps & {
  height?: string;
};

const CompactHeaderOrganism: React.FC<CompactHeaderOrganismProps> = ({
  className = 'compact-header-organism',
  height = 'auto',
}) => {
  const { t } = useInternationalization(APP_LOCALE_NAMESPACE);
  const { icvLogo } = useAssetsResources();
  const { toRoot } = useInternalNavigation();

  const renderLogo = () => (
    <LinkAtom
      className='compact-header__logo-link'
      to={toRoot()}
      underline='none'
      color='inherit'>
      <Stack
        className='compact-header__logo'
        direction='row'
        alignItems='center'
        spacing={1}
        paddingLeft={1}>
        <AvatarAtom
          className='compact-header__logo-image'
          src={icvLogo.src}
          alt={icvLogo.alt}
          shape={icvLogo.shape}
          sx={{ width: 30, height: 30 }}
        />
        <TypographyAtom
          className='compact-header__logo-text'
          component='h5'
          variant='h5'
          cursor='pointer'>
          {t(NAME_MAPPING)}
        </TypographyAtom>
      </Stack>
    </LinkAtom>
  );

  const renderToolbar = () => (
    <ThemeButtonMolecule className='compact-header__theme-button' />
  );

  return (
    <PaperBackgroundMolecule
      className={`${className} compact-header`}
      component='header'
      sx={{
        width: '100%',
        height,
        paddingY: 1,
        paddingX: '3%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      square>
      <Stack
        className={`${className} compact-header__content`}
        width='100%'
        maxWidth='900px'
        height='100%'
        direction='row'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'>
        {renderLogo()}
        {renderToolbar()}
      </Stack>
    </PaperBackgroundMolecule>
  );
};

export default CompactHeaderOrganism;
