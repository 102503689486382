import { Dispatch } from 'redux';

import FilterEntity from '../../../types/business/filter/stash/entity/FilterEntity';

import { SET_FILTER_ENTITY } from './cvActionTypes';

export type SetFilterEntity = {
  type: typeof SET_FILTER_ENTITY;
  payload: FilterEntity;
};

const setFilterEntity =
  (filterEntity: FilterEntity) => (dispatch: Dispatch<SetFilterEntity>) => {
    dispatch({ type: SET_FILTER_ENTITY, payload: filterEntity });
  };

export default setFilterEntity;
